import {
  faCartPlus,
  faCircleCheck,
  faCircleMinus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import moneyFormatter from "../../utils/moneyFormatter";

const CustomDomain = ({ item, data, handleOnToggleCart }) => {
  return (
    <tr
      className={`${
        item.status === "taken" ? "bg-[#f5f5f5]" : "bg-white"
      } my-1 dark:bg-neutral-700`}
    >
      <td className="whitespace-nowrap px-6 py-4 flex justify-center items-center">
        <div className="flex gap-x-2 justify-center text-lg mr-auto">
          <span>
            {item.status === "taken" ? (
              <FontAwesomeIcon
                icon={faXmark}
                className="text-slate-600 text-xl mr-1"
              />
            ) : (
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-cyan-600 text-lg"
              />
            )}
          </span>
          <span
            className={`${
              item.status === "taken" && "text-slate-600 line-through"
            }`}
          >
            {item.domainName.replace(/\.*$/, "").replace(/\.{2,}/g, ".")}
          </span>
        </div>
        <div
          className={`w-20 mr-4 ${
            item.status === "taken" ? "line-through" : ""
          }`}
        >
          <span className="text-base text-slate-600">
            {moneyFormatter(item.fee)} {item.unit}
          </span>
        </div>
        <div
          className="mx-4 md:ml-[50px] flex justify-center items-center cursor-pointer"
          onClick={() =>
            handleOnToggleCart({
              domainName: item.domainname,
              domainStatus:
                item.status === "taken" ? "Already Taken" : "Avaiable",
              domainFee: item.fee,
            })
          }
        >
          {data.find((domain) => domain.domainName === item.domainName) ? (
            <FontAwesomeIcon
              icon={faCircleMinus}
              size="xl"
              className="text-red-400"
            />
          ) : (
            <FontAwesomeIcon
              icon={faCartPlus}
              size="xl"
              className={`${
                item.status === "taken" ? "text-gray-300" : "text-gray-600"
              }`}
            />
          )}
        </div>
      </td>
    </tr>
  );
};

export default CustomDomain;
