import React from "react";

//images
import Logo from "../../assets/imgs/AGB_Logo.png";

const Header = () => {
  return (
    <div className="grid grid-cols-12 gap-y-2 md:gap-y-0">
      <div className="col-span-12 md:col-span-3 lg:col-span-2">
        <img
          src={Logo}
          className="w-40 mx-auto md:w-44 lg:w-36 md:mx-0 h-auto"
          alt="Logo"
        />
      </div>
      <div className="col-span-12 md:col-span-9 lg:col-span-10">
        <div className="mt-2">
          <p className="heading-font text-center md:text-left">
            AGB COMMUNICATION CO., LTD
          </p>
          <p className="body-font text-center md:text-left">
            19th Floor, Office Tower (3), Times City, No.37, Between
            Hantharwaddy Rd & Kyun Taw Rd, Kamaryut Township, Yangon, Myanmar.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
