import React from "react";
import Header from "../Header/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faEnvelope,
  faGlobe,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const PageHeader = () => {
  return (
    <>
      <Header />
      <div className="px-4 mt-8">
        <div className="grid grid-cols-2 gap-y-4">
          <div className="col-span-2 md:col-span-1 md:place-self-start flex space-x-3">
            <FontAwesomeIcon
              icon={faCalendarDays}
              size="lg"
              className="text-slate-400"
            />
            <p className="body-font font-medium">{new Date().toDateString()}</p>
          </div>
          <div className="col-span-2 md:col-span-1 md:place-self-end flex space-x-3">
            <FontAwesomeIcon
              icon={faPhone}
              size="lg"
              className="text-slate-400"
            />
            <div className="flex justify-center flex-col gap-y-2">
              <p>
                <a
                  href="tel:+959977878889"
                  className="body-font text-blue-500 font-medium hover:underline"
                >
                  (+95) 9 97787 8889
                </a>{" "}
                &nbsp;
              </p>
              <p>
                <a
                  href="tel:+959797178889"
                  className="body-font text-blue-500 font-medium hover:underline"
                >
                  (+95) 9 79717 8889
                </a>
              </p>
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 md:place-self-start flex space-x-3">
            <FontAwesomeIcon
              icon={faEnvelope}
              size="lg"
              className="text-slate-400"
            />
            <a
              href="mailto:ispsale@agbcommunication.com"
              className="body-text text-blue-500 font-medium hover:underline"
            >
              ispsale@agbcommunication.com
            </a>
          </div>
          <div className="col-span-2 md:col-span-1 md:float-right md:place-self-end flex space-x-3">
            <FontAwesomeIcon
              icon={faGlobe}
              size="lg"
              className="text-slate-400"
            />
            <a
              href="https://www.agbcommunication.com"
              className="body-font text-blue-500 font-medium hover:underline"
              target="_blank"
            >
              https://www.agbcommunication.com
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageHeader;
