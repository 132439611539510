import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
//components
import ErrorMessage from "../MessageTexts/error_message.js";

const Input = ({
  type = "text",
  name = "",
  placeholder = "",
  isRequired = false,
  isReadOnly = false,
  isAutofocus = false,
  dataValue = "",
  constantValue = "",
  errorMessage = "",
  commentMessage = "",
  isSearch = false,
  inputClass,
  domainName,
  /**
   * action
   */
  handleOnChangeInput,
  handleOnRequestMoreDomain,
}) => {
  return (
    <div className="flex justify-center">
      <div className="flex justify-center flex-col items-center bg-blue-500 text-white rounded-l-lg p-2">
        {
          domainName
            .trim()
            .toLowerCase()
            .replace(/\.*$/, "")
            .replace(/\.{2,}/g, ".")
            .split(".")[0]
        }
        .
      </div>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        required={isRequired}
        readOnly={isReadOnly}
        autoFocus={isAutofocus}
        value={dataValue}
        className={`w-full h-auto py-3 px-4 border border-gray-300 focus:outline-none ${
          errorMessage && dataValue
            ? "focus:border-red-500 focus:ring-1 focus:ring-red-500"
            : "focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
        }  placeholder:italic placeholder:text-slate-400  ${inputClass}`}
        /**
         * action
         */
        onChange={(e) => handleOnChangeInput(e)}
      />
      <div className="flex justify-center flex-col items-center bg-blue-500 text-white p-2 border-r">
        .mm
      </div>
      {isSearch && (
        <button
          className="bg-gray-600 mr-auto rounded-r-lg px-4 flex justify-center items-center"
          onSubmit={handleOnRequestMoreDomain}
        >
          <FontAwesomeIcon icon={faPlus} size="lg" className="text-white" />
        </button>
      )}
      {dataValue
        ? errorMessage && <ErrorMessage text={errorMessage} />
        : commentMessage && (
            <p className="text-teal-400">
              <small>({commentMessage})</small>
            </p>
          )}
      {/* {errorMessage && dataValue && <ErrorMessage text={errorMessage} />} */}
    </div>
  );
};

export default Input;
