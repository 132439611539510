import React, { useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble, faXmark } from "@fortawesome/free-solid-svg-icons";

//components
import SubHeader from "../../../ControlledComponents/Header/sub_header.js";
import CheckBox from "../../../ControlledComponents/Inputs/check_box.js";
import FloatInput from "../../../ControlledComponents/Inputs/float_input_field";
import ErrorMessage from "../../../ControlledComponents/MessageTexts/error_message";
import { LIST_OF_DOMAIN_MANAGEMENT } from "../../../constants/constantData";
import Button from "../../../ControlledComponents/Buttons/button";

const RequestIPPointing = ({
  formData,
  data,
  errors,
  /**
   * action
   */
  handleOnChangeInput,
  handleOnClickDNSService,
  handleClickOnAddAndRemove,
  responeDomain,
}) => {
  const [isShow, setIsShow] = useState(true);
  const requireInput = useRef(null);
  useEffect(() => {
    if (typeof formData.radioBoxIPPointing === "undefined") {
      requireInput.current.setAttribute("required", "required");
    } else {
      requireInput.current.removeAttribute("required");
    }
  }, [formData]);

  return (
    <div className="py-4 z-10">
      <SubHeader
        subHeaderName="DNS Management Service"
        isShow={isShow}
        setIsShow={setIsShow}
        isShowInfo={true}
        toolTipText="AGB will support binding Sub Domain with Host IP Address."
      />
      {isShow && (
        <div className="pt-5 space-y-4 relative">
          {/* Self Domain Mangement */}
          <div className="grid grid-cols-5 gap-y-3">
            <div className="col-span-5 md:col-span-2">
              <p className="body-font md:mt-4">
                <span className="text-red-500">**</span> Self Domain Management
              </p>
            </div>
            <div className="flex justify-center items-center flex-col absolute w-full z-0">
              <input
                type="text"
                ref={requireInput}
                className="w-0 overflow-hidden opacity-0"
              />
            </div>

            <div className="col-span-5 md:col-span-3 z-10">
              <div className="flex items-center w-full lg:w-3/4 h-auto space-x-5">
                {LIST_OF_DOMAIN_MANAGEMENT.map((dns) => (
                  <div
                    key={dns.id}
                    className={`w-full h-auto rounded-lg shadow-sm md:cursor-pointer px-4 py-3 border-2 ${
                      formData.radioBoxIPPointing === dns.value
                        ? formData.radioBoxIPPointing
                          ? "border-blue-500 text-black hover:shadow-sm hover:shadow-blue-400"
                          : "border-amber-500 hover:shadow-sm hover:shadow-amber-400"
                        : "text-gray-400 hover:shadow-sm hover:shadow-gray-200"
                    } flex justify-between items-center space-x-3`}
                    onClick={() => handleOnClickDNSService(dns.value)}
                  >
                    <div className="flex items-center space-x-3">
                      <div className="w-4 h-4 border border-gray-400 rounded-full flex items-center justify-center">
                        <div
                          className={`w-3 h-3 border rounded-full ${
                            formData.radioBoxIPPointing !== dns.value &&
                            "scale-0"
                          } ${
                            formData.radioBoxIPPointing
                              ? "bg-blue-500"
                              : "bg-amber-500"
                          } duration-300`}
                        />
                      </div>

                      <p className="body-font"> {dns.text} </p>
                    </div>
                    <div>
                      {dns.value ? (
                        <FontAwesomeIcon
                          icon={faCheckDouble}
                          className={`${
                            formData.radioBoxIPPointing
                              ? "text-blue-700"
                              : "text-gray-400"
                          }`}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faXmark}
                          className={`${
                            !formData.radioBoxIPPointing
                              ? "text-amber-500"
                              : "text-gray-400"
                          } `}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {/* {responeDomain &&
                data &&
                responeDomain.length > 2 &&
                formData.radioBoxIPPointing === undefined &&
                errors.selfDomainManagement && (
                  <ErrorMessage text={errors.selfDomainManagement} />
                )} */}
            </div>
          </div>
          {/* Domain/SubDomain Name */}
          {formData.radioBoxIPPointing === false && (
            <>
              {formData.DNSFieldArray.map((field, index) => (
                <div
                  key={index}
                  className="grid grid-cols-12 gap-y-6 lg:gap-x-3 border lg:border-0 rounded-md p-5 md:p-8 lg:p-0 shadow-sm lg:shadow-none"
                >
                  <div className="col-span-12 lg:col-span-5">
                    <FloatInput
                      indx={index}
                      type="text"
                      name="subDomainName"
                      placeHolderText={
                        <>
                          <span className="text-red-500">**</span> &nbsp; Sub
                          Domain Name
                        </>
                      }
                      isRequired={true}
                      dataValue={field.subDomainName}
                      // descriptiveText={
                      //   formData.domainName &&
                      //   field.subDomainName &&
                      //   `${field.subDomainName}.${data.map}`
                      // }
                      /**
                       * action
                       */
                      handleOnChangeInput={handleOnChangeInput}
                    />
                  </div>
                  <div className="col-span-12 lg:col-span-5">
                    <FloatInput
                      indx={index}
                      type="text"
                      name="hostIPAddress"
                      placeHolderText={
                        <>
                          <span className="text-red-500">**</span> &nbsp; Host
                          IP Address
                        </>
                      }
                      isRequired={true}
                      dataValue={field.hostIPAddress}
                      errorMessage={errors.hostIPAddress[index]}
                      helpText="Please provide Public IP!"
                      /**
                       * action
                       */
                      handleOnChangeInput={handleOnChangeInput}
                    />
                  </div>
                  <div className="col-span-12 lg:col-span-2">
                    {formData.DNSFieldArray.length > 1 && (
                      <div className="float-right lg:float-none w-2/5 h-auto md:w-1/5 lg:w-full">
                        <Button
                          type="button"
                          text="Remove"
                          bgColor="bg-red-500"
                          txtColor="text-white"
                          /**
                           * action
                           */
                          clickingOn={() =>
                            handleClickOnAddAndRemove("remove", index)
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
              {formData.DNSFieldArray.length < 3 && (
                <div className="w-2/5 md:w-1/6 h-auto">
                  <Button
                    type="button"
                    text="Add"
                    bgColor="bg-green-500"
                    txtColor="text-white"
                    /**
                     * action
                     */
                    clickingOn={() => handleClickOnAddAndRemove("add")}
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default RequestIPPointing;
