import React, { useContext } from "react";

//components
import { Hook } from "./hook.js";
import Button from "../../ControlledComponents/Buttons/button.js";
import Divider from "../../ControlledComponents/Divider";
import DomainNameInfo from "./DomainNameInfo";
import RequestIPPointing from "./RequestIPPointing";
import ApplicantInfo from "./ApplicantInfo";
import BillingInfo from "./BillingInfo";
import TechInfo from "./TechInfo";
import ServiceTypeCharges from "./ServiceTypeCharges";
import TermsAndConditions from "./TermsAndConditions";
import PageHeader from "../../ControlledComponents/PageHeader/header.js";

const RegistrationPage = () => {
  const [
    formData,
    data,
    setFormData,
    errors,
    /**
     * action
     */
    handleOnChangeInput,
    handleClickOnDomainType,
    handleClickOnServiceType,
    handleClickOnAddAndRemove,
    handleChangeOnPicker,
    handleClickOnFees,
    handleOnChangeRequestDomainInput,
    handleOnRequestMoreDomain,
    handleOpenDomainBox,
    handleOnDomainfoNext,
    handleOnDomainPrev,
    /**
     * service
     */
    toggleDNSService,
    onSubmit,
    onReset,
    setOtherDomainType,
    isOther,
    isSearch,
    priority,
    otherDomainType,
    requestDomainValue,
    customDomain,
    nextStap,
    isRegisterError,
  ] = Hook();
  return (
    <div className="mx-auto container py-4 px-2 md:px-10 mb-10">
      <div
        className={`fixed top-0 right-0 m-11 bg-red-500 text-white p-5 font-semibold text-lg transition-all z-20 ${
          isRegisterError ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      >
        You don't have domain
      </div>
      <PageHeader />
      {/* Form Filed */}
      <div className="px-2">
        {nextStap === 0 && (
          <div>
            <DomainNameInfo
              formData={formData}
              /**
               * action
               */
              handleOnChangeInput={handleOnChangeInput}
              handleClickOnDomainType={handleClickOnDomainType}
              setClickSearch={handleOpenDomainBox}
              isSearch={isSearch}
              domainName={priority}
              isOther={isOther}
              otherDomainType={otherDomainType}
              handleOnChangeRequestDomainInput={
                handleOnChangeRequestDomainInput
              }
              requestDomainValue={requestDomainValue}
              handleOnRequestMoreDomain={handleOnRequestMoreDomain}
              requestMoreDomain={requestDomainValue}
              customDomain={customDomain}
              errors={errors}
              toggleDNSService={toggleDNSService}
              handleClickOnAddAndRemove={handleClickOnAddAndRemove}
              handleClickOnServiceType={handleClickOnServiceType}
              handleChangeOnPicker={handleChangeOnPicker}
              handleClickOnFees={handleClickOnFees}
              handleOnDomainfoNext={handleOnDomainfoNext}
              nextStap={nextStap}
            />
          </div>
        )}

        {nextStap === 1 && (
          <form onSubmit={onSubmit}>
            <ApplicantInfo
              formData={formData}
              errors={errors}
              handleOnChangeInput={handleOnChangeInput}
            />
            <Divider />
            <TechInfo
              formData={formData}
              errors={errors}
              handleOnChangeInput={handleOnChangeInput}
            />
            <Divider />
            <BillingInfo
              formData={formData}
              errors={errors}
              /**
               * action
               */
              handleOnChangeInput={handleOnChangeInput}
            />
            <Divider />
            <div className="flex justify-between items-center w-full my-4">
              <div className="w-48 my-3">
                <Button
                  type="BUTTON"
                  text="Back"
                  bgColor="bg-slate-500"
                  txtColor="text-white"
                  nextStap={nextStap}
                  clickingOn={handleOnDomainPrev}
                  formData={formData}
                />
              </div>
              <div className="w-48 my-3">
                <Button
                  type="submit"
                  text="Next"
                  bgColor={`${
                    (nextStap === 1 && !formData.representName) ||
                    !formData.nrcPassNumber ||
                    !formData.appContactName ||
                    !formData.appContactEmail ||
                    !formData.appContactPhone ||
                    !formData.appAddress ||
                    !formData.techContactName ||
                    !formData.techContactEmail ||
                    !formData.techContactPhone ||
                    !formData.billContactName ||
                    !formData.billContactEmail ||
                    !formData.billContactPhone ||
                    !formData.billAddress ||
                    !data.length
                      ? "bg-blue-400 cursor-not-allowed"
                      : "bg-blue-500"
                  }`}
                  txtColor="text-white"
                />
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default RegistrationPage;
