import React from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateTimePicker = ({
  selectedDate = "",
  min = "",
  max = "",
  placeHolder = "",
  /**
   * action
   */
  handleChangeOnPicker,
}) => {
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div
      className="cursor-pointer w-full h-auto py-3 px-4 border rounded-lg border-gray-300"
      onClick={onClick}
      ref={ref}
    >
      {value ? (
        <span className="text-black">{value}</span>
      ) : (
        <span className="text-slate-400 italic"> {placeHolder} </span>
      )}
    </div>
  ));

  return (
    <>
      <DatePicker
        closeOnScroll={true}
        selected={selectedDate}
        onChange={handleChangeOnPicker}
        dateFormat="dd MMMM yyyy"
        minDate={min}
        maxDate={max}
        customInput={<CustomInput />}
      />
    </>
  );
};

export default DateTimePicker;
