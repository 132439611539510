import React from "react";
import moneyFormatter from "../../utils/moneyFormatter";

// Define a functional component named 'Item' for displaying individual items
const Item = ({
  labelName = "",
  valueName = "",
  isPrice = false,
  price = "",
  labelWidth = false,
}) => {
  return (
    <div
      className={`flex items-center justify-between w-full ${
        isPrice && "mb-3"
      }`}
    >
      <div className="col-span-1 text-sm text-gray-600">{labelName}</div>
      <div
        className={`col-span-1 text-sm text-black font-medium ${
          labelWidth ? "mr-auto ml-3" : "ml-auto"
        }`}
      >
        {valueName}
      </div>
      {isPrice && (
        <div className="col-span-1 text-md font-medium text-gray-600">
          {moneyFormatter(price)} MMK
        </div>
      )}
    </div>
  );
};

export default Item;
