import React from "react";

const TextArea = ({
  name = "",
  placeHolderText = "",
  isRequired = false,
  isDisabled = false,
  isReadOnly = false,
  dataValue = "",
  errorMessage = "",
  /**
   * action
   */
  handleOnChangeTextArea,
}) => {
  return (
    <>
      <div className="w-full h-auto md:grid md:grid-cols-5 md:gap-x-2">
        <div className="hidden md:block col-span-2 md:mt-3">
          <p className="body-font">{placeHolderText}</p>
        </div>
        <div className="relative group md:col-span-3">
          <textarea
            id={name}
            name={name}
            required={isRequired}
            disabled={isDisabled}
            readOnly={isReadOnly}
            value={dataValue}
            className="w-full h-auto shadow-sm pl-5 pr-12 py-4 md:py-3 primary-font peer bg-white text-black border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-[#003FDA] focus:shadow-md"
            /**
             * action
             */
            onChange={(e) => handleOnChangeTextArea(e)}
          />
          <label
            htmlFor={name}
            className="md:hidden body-font text-slate-400 w-auto h-auto transform transition-all absolute top-5 md:top-3 left-5 flex items-center px-2 group-focus-within:bg-white group-focus-within:rounded-md group-focus-within:t-color-primary group-focus-within:caption-font group-focus-within:h-1/3 group-focus-within:-translate-y-full cursor-text
              peer-valid:-translate-y-full peer-valid:bg-white peer-valid:h-1/2 peer-valid:caption-font"
          >
            {placeHolderText}
          </label>
        </div>
      </div>
    </>
  );
};

export default TextArea;
