import React, { useContext } from "react";
import masterDataProps from "../../utils/masterDataProp";

const Button = ({
  type,
  text,
  bgColor,
  txtColor,
  disabled = false,
  /**
   * action
   */
  clickingOn,
}) => {
  return (
    <button
      type={type}
      className={`${bgColor} ${txtColor} border rounded-lg w-full h-auto py-3`}
      disabled={disabled}
      /**
       * action
       */
      onClick={clickingOn}
    >
      <p className="body-font">{text}</p>
    </button>
  );
};

export default Button;
