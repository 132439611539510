import React, { useCallback, useEffect, useState } from "react";
import {
  faGlobe,
  faFileInvoice,
  faNetworkWired,
  faCoins,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { Navigate } from "react-router-dom";

// Import custom Hook
import { Hook } from "./hook.js";

// Import components
import Header from "../../ControlledComponents/Header/header.js";
import Card from "../../ControlledComponents/Card/card.js";
import moneyFormatter from "../../utils/moneyFormatter.js";
import Button from "../../ControlledComponents/Buttons/button.js";
import Loading from "./Loading.js";
import FormatServiceDate from "./FormatServiceDate.js";
import Item from "./Item.js";

// Define the main ConfirmationPage component
const ConfirmationPage = () => {
  // Use the custom Hook to get data and functions
  const [
    formData,
    data,
    isConfirmLoading,
    // Action functions
    handleClickOnConfirm,
    handleClickOnCancel,
    // Service calculator function
    serviceCalculator,
  ] = Hook();

  // State for holding the total fees
  const [total, setTotal] = useState(0);

  // Function to calculate and set the total fees
  const totalFee = useCallback(() => {
    if (data.length > 1) {
      let total = 0;
      for (let i = 0; i < data.length; i++) {
        total += data[i].domainFee;
      }
      setTotal(moneyFormatter(total));
    } else if (data.length === 1) {
      setTotal(moneyFormatter(data[0].domainFee));
    }
  }, [data]);

  // Effect to calculate total fees on mount
  useEffect(() => {
    totalFee();
  }, []);
  // Redirect to the home page if formData is not available
  if (!formData) {
    return <Navigate to="/" />;
  }

  // JSX rendering
  return (
    <div className="mx-auto container py-4 px-2 md:px-10">
      <Header />
      {isConfirmLoading && <Loading />}
      <div className="block lg:flex w-full h-auto mt-4 mb-8">
        {/* Left Column */}
        <div className="w-full lg:w-1/2 h-auto p-3">
          <div className="flex flex-col space-y-4">
            {/* Domain Name Info */}
            <Card
              Icon={faGlobe}
              Title="Domain Name Information"
              List={
                <div className="flex justify-center flex-col gap-2 relative py-10 pb-16">
                  {data?.map((domain, index) => {
                    return (
                      <Item
                        key={index}
                        labelName="Domain Name"
                        valueName={domain.domainName}
                        isPrice={true}
                        price={domain.domainFee}
                        labelWidth={true}
                      />
                    );
                  })}
                  <div className="flex justify-center items-start flex-col gap-3 w-full absolute bottom-0 translate-y-3">
                    <div className="flex justify-between w-full items-center">
                      <div>
                        <span className="col-span-1 text-sm text-gray-600">
                          {formData.selectedServiceType.id === 1
                            ? "Total Annual Fees"
                            : "Renewal Date"}
                        </span>
                        <span className="text-teal-400">
                          <small> (Exclusive Tax) </small>
                        </span>
                      </div>
                      <span className="text-sm text-black font-medium ml-3">
                        {moneyFormatter(total)} MMK
                      </span>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <span className="col-span-1 text-sm text-gray-600">
                        Service Duration
                      </span>
                      <span className="text-sm text-black font-medium ml-3">
                        {formData.selectedServiceType.id === 1 ? (
                          <FormatServiceDate
                            dateTime={formData.selectedActivationDate}
                            serviceDateCalculator={serviceCalculator}
                          />
                        ) : (
                          <FormatServiceDate
                            dateTime={formData.selectedRenewalDate}
                            serviceDateCalculator={serviceCalculator}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              }
            />
            {/* Request IP Pointing */}
            <Card
              Icon={faNetworkWired}
              Title="Request For IP Pointing(Local/Foreign)"
              isChecked={formData.radioBoxIPPointing}
              List={
                <div className="flex justify-center flex-col gap-3">
                  {formData.DNSFieldArray.map((field, index) => (
                    <React.Fragment key={index}>
                      <Item
                        labelName="Domain/Sub Domain Name"
                        valueName={`${
                          !formData.radioBoxIPPointing
                            ? field.subDomainName
                            : "-"
                        }`}
                      />
                      <Item
                        labelName="Hosting IP Address"
                        valueName={`${
                          !formData.radioBoxIPPointing
                            ? field.hostIPAddress
                            : "-"
                        }`}
                      />
                    </React.Fragment>
                  ))}
                </div>
              }
            />
          </div>
        </div>

        {/* Right Column */}
        <div className="w-full lg:w-1/2 h-auto p-3">
          <div className="flex flex-col space-y-4">
            {/* Applicant Info */}
            <Card
              Icon={faFileInvoice}
              Title="Applicant Information"
              List={
                <div className="flex justify-center flex-col gap-3">
                  <Item
                    labelName="Representative's Name"
                    valueName={`${formData.representName}`}
                  />
                  <Item
                    labelName="NRC or Passport Number"
                    valueName={`${formData.nrcPassNumber}`}
                  />
                  <Item
                    labelName="Company's Name"
                    valueName={`${
                      formData.companyName ? formData.companyName : "-"
                    }`}
                  />
                  <Item
                    labelName="Company's Registration Number"
                    valueName={`${
                      formData.companyRegisterNumber
                        ? formData.companyRegisterNumber
                        : "-"
                    }`}
                  />
                  <Item
                    labelName="Contact Name"
                    valueName={`${formData.appContactName}`}
                  />
                  <Item
                    labelName="Contact Email"
                    valueName={`${formData.appContactEmail}`}
                  />
                  <Item
                    labelName="Contact Phone"
                    valueName={`${formData.appContactCode}${formData.appContactPhone}`}
                  />
                  <Item
                    labelName="Address"
                    valueName={`${formData.appAddress}`}
                  />
                </div>
              }
            />
            {/* Tech Info */}
            <Card
              Icon={faWrench}
              Title="Technical Contact Information"
              isChecked={formData.checkBoxTechInfo}
              List={
                <div className="flex justify-center flex-col gap-3">
                  <Item
                    labelName="Contact Name"
                    valueName={`${formData.techContactName}`}
                  />
                  <Item
                    labelName="Contact Email"
                    valueName={`${formData.techContactEmail}`}
                  />
                  <Item
                    labelName="Contact Phone Number"
                    valueName={`${formData.techContactCode}${formData.techContactPhone}`}
                  />
                </div>
              }
            />
            {/* Billing Info */}
            <Card
              Icon={faCoins}
              Title="Billing Information"
              isChecked={formData.checkBoxBillingInfo}
              List={
                <div className="flex justify-center flex-col gap-3">
                  <Item
                    labelName="Contact Name"
                    valueName={`${formData.billContactName}`}
                  />
                  <Item
                    labelName="Contact Email"
                    valueName={`${formData.billContactEmail}`}
                  />
                  <Item
                    labelName="Contact Phone Number"
                    valueName={`${formData.billContactCode}${formData.billContactPhone}`}
                  />
                  <Item
                    labelName="Office Phone Number"
                    valueName={`${
                      formData.billOfficePhone
                        ? `${formData.billOfficeCode}${formData.billOfficePhone}`
                        : "-"
                    }`}
                  />
                  <Item
                    labelName="Address"
                    valueName={`${formData.billAddress}`}
                  />
                </div>
              }
            />
          </div>
        </div>
      </div>

      {/* Buttons for Confirm and Cancel */}
      <div className="flex justify-between items-center">
        <div className="w-56">
          <Button
            type="button"
            text="Cancel"
            bgColor="bg-slate-500"
            txtColor="text-white"
            /**
             * action
             */
            clickingOn={() => handleClickOnCancel()}
          />
        </div>
        <div className="w-56">
          <Button
            type="button"
            text="Confirm"
            bgColor="bg-blue-500"
            txtColor="text-white"
            disabled={isConfirmLoading}
            /**
             * action
             */
            clickingOn={() => handleClickOnConfirm()}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
