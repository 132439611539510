import React, { useState } from "react";

//components
import SubHeader from "../../../ControlledComponents/Header/sub_header.js";
import CheckBox from "../../../ControlledComponents/Inputs/check_box.js";
import FloatInput from "../../../ControlledComponents/Inputs/float_input_field";
import ErrorMessage from "../../../ControlledComponents/MessageTexts/error_message.js";

const TechInfo = ({
  formData,
  errors,
  /**
   * action
   */
  handleOnChangeInput,
}) => {
  const [isShow, setIsShow] = useState(true);
  return (
    <div className="py-4">
      <SubHeader
        subHeaderName="Technical Contact Information"
        isShow={isShow}
        setIsShow={setIsShow}
      />
      {isShow && (
        <div className="pt-5 space-y-4">
          <div className="grid grid-cols-5 gap-y-3">
            <div className="col-span-4 md:col-span-2">
              <p className="body-font md:mt-1">
                Same as above Applicant Information?
              </p>
            </div>
            <div className="col-span-1 md:col-span-3">
              <CheckBox
                name="checkBoxTechInfo"
                isChecked={formData.checkBoxTechInfo}
                /**
                 * action
                 */
                handleOnChangeCheckBox={handleOnChangeInput}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-y-6 lg:gap-x-3">
            <div className="col-span-2 lg:col-span-1">
              <FloatInput
                type="text"
                name="techContactName"
                placeHolderText={
                  <>
                    <span className="text-red-500">**</span> &nbsp; Technical Contact Name
                  </>
                }
                isRequired={true}
                isReadOnly={formData.checkBoxTechInfo}
                dataValue={formData.techContactName}
                /**
                 * action
                 */
                handleOnChangeInput={handleOnChangeInput}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <FloatInput
                type="text"
                name="techContactEmail"
                placeHolderText={
                  <>
                    <span className="text-red-500">**</span> &nbsp; Technical Contact
                    Email
                  </>
                }
                isRequired={true}
                isReadOnly={formData.checkBoxTechInfo}
                dataValue={formData.techContactEmail}
                errorMessage={errors.techContactEmail}
                /**
                 * action
                 */
                handleOnChangeInput={handleOnChangeInput}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <FloatInput
                type="number"
                name="techContactPhone"
                placeHolderText={
                  <>
                    <span className="text-red-500">**</span> &nbsp; Technical Contact
                    Phone Number
                  </>
                }
                isRequired={true}
                isReadOnly={formData.checkBoxTechInfo}
                dataValue={formData.techContactPhone}
                errorMessage={errors.techContactPhone}
                isPhoneInput={true}
                dropDownName="techContactCode"
                selectedDropDownValue={formData.techContactCode}
                /**
                 * action
                 */
                handleOnChangeInput={handleOnChangeInput}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TechInfo;
