import React, { useState } from "react";

//components
import SubHeader from "../../../ControlledComponents/Header/sub_header.js";
import CheckBox from "../../../ControlledComponents/Inputs/check_box.js";
import FloatInput from "../../../ControlledComponents/Inputs/float_input_field";
import ErrorMessage from "../../../ControlledComponents/MessageTexts/error_message.js";
import TextArea from "../../../ControlledComponents/Inputs/text_area.js";

const BillingInfo = ({
  formData,
  errors,
  /**
   * action
   */
  handleOnChangeInput,
}) => {
  const [isShow, setIsShow] = useState(true);
  return (
    <div className="py-4">
      <SubHeader
        subHeaderName="Billing Information"
        isShow={isShow}
        setIsShow={setIsShow}
      />
      {isShow && (
        <div className="pt-5 space-y-4">
          <div className="grid grid-cols-5 gap-y-3">
            <div className="col-span-4 md:col-span-2">
              <p className="body-font md:mt-1">
                Same as above Applicant Information?
              </p>
            </div>
            <div className="col-span-1 md:col-span-3">
              <CheckBox
                name="checkBoxBillingInfo"
                isChecked={formData.checkBoxBillingInfo}
                /**
                 * action
                 */
                handleOnChangeCheckBox={handleOnChangeInput}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-y-6 lg:gap-x-3">
            <div className="col-span-2 lg:col-span-1">
              <FloatInput
                type="text"
                name="billContactName"
                placeHolderText={
                  <>
                    <span className="text-red-500">**</span> &nbsp; Billing
                    Contact Name
                  </>
                }
                isRequired={true}
                isReadOnly={formData.checkBoxBillingInfo}
                dataValue={formData.billContactName}
                /**
                 * action
                 */
                handleOnChangeInput={handleOnChangeInput}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <FloatInput
                type="text"
                name="billContactEmail"
                placeHolderText={
                  <>
                    <span className="text-red-500">**</span> &nbsp; Billing
                    Contact Email
                  </>
                }
                isRequired={true}
                isReadOnly={formData.checkBoxBillingInfo}
                dataValue={formData.billContactEmail}
                errorMessage={errors.billContactEmail}
                /**
                 * action
                 */
                handleOnChangeInput={handleOnChangeInput}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <FloatInput
                type="number"
                name="billContactPhone"
                placeHolderText={
                  <>
                    <span className="text-red-500">**</span> &nbsp; Billing
                    Contact Phone Number
                  </>
                }
                isRequired={true}
                isReadOnly={formData.checkBoxBillingInfo}
                dataValue={formData.billContactPhone}
                errorMessage={errors.billContactPhone}
                isPhoneInput={true}
                dropDownName="billContactCode"
                selectedDropDownValue={formData.billContactCode}
                /**
                 * action
                 */
                handleOnChangeInput={handleOnChangeInput}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <FloatInput
                type="number"
                name="billOfficePhone"
                placeHolderText={<>Office Phone Number </>}
                dataValue={formData.billOfficePhone}
                errorMessage={errors.billOfficePhone}
                isPhoneInput={true}
                dropDownName="billOfficeCode"
                selectedDropDownValue={formData.billOfficeCode}
                /**
                 * action
                 */
                handleOnChangeInput={handleOnChangeInput}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <TextArea
                type="text"
                name="billAddress"
                placeHolderText={
                  <>
                    <span className="text-red-500">**</span> &nbsp; Billing
                    Address
                  </>
                }
                isRequired={true}
                dataValue={formData.billAddress}
                /**
                 * action
                 */
                handleOnChangeTextArea={handleOnChangeInput}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BillingInfo;
