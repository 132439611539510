import React from "react";

const CheckBox = ({
  name = "",
  isChecked = false,
  isRequired = false,
  isReadOnly = false,
  /**
   * action
   */
  handleOnChangeCheckBox,
}) => {
  return (
    <>
      <input
        type="checkbox"
        name={name}
        required={isRequired}
        readOnly={isReadOnly}
        className="bg-gray-100 border-gray-300 text-blue-500 h-6 w-6 rounded-md shadow cursor-pointer focus:outline-0 duration-300"
        checked={isChecked}
        /**
         * action
         */
        onChange={(e) => handleOnChangeCheckBox(e)}
      />
    </>
  );
};

export default CheckBox;
