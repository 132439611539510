import React from "react";

const ErrorMessage = ({
    text = ''
}) => {
  return (
    <>
      <p className="text-red-500">
        <small> {text} </small>
      </p>
    </>
  );
};

export default ErrorMessage;
