import React from "react";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

//components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

const SubHeader = (props) => {
  return (
    <>
      <div className="w-full flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <p className="sub-heading-font text-slate-400">
            {props.subHeaderName}
          </p>
        </div>
      </div>
    </>
  );
};

export default SubHeader;
