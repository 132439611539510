import React, { useContext, useEffect, useRef, useState } from "react";
import SubHeader from "../../../ControlledComponents/Header/sub_header.js";
import FloatInput from "../../../ControlledComponents/Inputs/float_input_field";
import Badge from "../../../ControlledComponents/Badge/badge.js";
import Priority from "../../../ControlledComponents/Priority";
import RequestDomain from "../../../ControlledComponents/Buttons/RequestDomain.js";
import Button from "../../../ControlledComponents/Buttons/button.js";
import masterDataProps from "../../../utils/masterDataProp.js";
import Divider from "../../../ControlledComponents/Divider/index.js";
import RequestIPPointing from "../RequestIPPointing/index.js";
import ServiceTypeCharges from "../ServiceTypeCharges/index.js";
import TermsAndConditions from "../TermsAndConditions/index.js";

const DomainNameInfo = ({
  formData,
  handleOnChangeInput,
  handleClickOnDomainType,
  handleOnChangeRequestDomainInput,
  handleOnRequestMoreDomain,
  isSearch,
  setClickSearch,
  domainName,
  otherDomainType,
  isOther,
  requestDomainValue,
  customDomain,
  errors,
  toggleDNSService,
  handleClickOnAddAndRemove,
  handleClickOnServiceType,
  handleChangeOnPicker,
  handleClickOnFees,
  handleOnDomainfoNext,
  nextStap,
}) => {
  const [
    setData,
    setIsOpen,
    setRequestDomain,
    setResponeDomain,
    setIsLoading,
    setIsRequestMoreDomainLoading,
    setIsDomainError,
    data,
    isOpen,
    requestDomain,
    responeDomain,
    isLoading,
    isRequestMoreDomainLoading,
    isDomainError,
  ] = useContext(masterDataProps);
  const [screenWidth, setScreenWidth] = useState(false);
  const foucsInputRef = useRef(null);
  window.addEventListener("resize", () => {
    if (window.innerWidth < 740) {
      return setScreenWidth(true);
    }
    return setScreenWidth(false);
  });

  return (
    <div className="py-4">
      <div className="pt-2 pb-4">
        <SubHeader subHeaderName="Domain Name Information" />
      </div>
      <div className="pt-5 space-y-4">
        <form onSubmit={setClickSearch}>
          {screenWidth ? (
            <FloatInput
              type="text"
              name="domainName"
              isRequired={true}
              dataValue={formData.domainName
                .trim()
                .toLowerCase()
                .replace(/[^\w\s.-]/g, "")}
              placeholder="Search"
              handleOnChangeInput={handleOnChangeInput}
              isSearchInput={true}
              setClickSearch={setClickSearch}
              isAutofocus={true}
              foucsInputRef={foucsInputRef}
            />
          ) : (
            <FloatInput
              type="text"
              name="domainName"
              placeHolderText={
                <>
                  <span className="text-red-500">**</span> &nbsp; Domain Name
                </>
              }
              isRequired={true}
              dataValue={formData.domainName
                .trim()
                .toLowerCase()
                .replace(/[^\w\s.-]/g, "")}
              placeholder="Search"
              handleOnChangeInput={handleOnChangeInput}
              isSearchInput={true}
              setClickSearch={setClickSearch}
              isAutofocus={true}
              foucsInputRef={foucsInputRef}
            />
          )}
          <div className="w-full h-auto"></div>
          <Priority
            otherDomainType={otherDomainType}
            isOther={isOther}
            fee={formData.selectedActivationFee.fee}
            unit={formData.selectedActivationFee.unit}
            domainName={domainName}
            isSearch={isSearch}
            customDomain={customDomain}
          />
        </form>
      </div>
      <form
        onSubmit={handleOnRequestMoreDomain}
        className="flex justify-between items-center ml-auto w-full  my-4 mt-8"
      >
        {!isDomainError && isSearch && !isLoading && (
          <span className="body-font md:inline-block hidden">
            <span className="text-red-500">**</span>&nbsp; Request More Domain
          </span>
        )}
        <RequestDomain
          domainName={domainName.trim().toLowerCase()}
          handleOnRequestMoreDomain={handleOnRequestMoreDomain}
          handleOnChangeRequestDomainInput={handleOnChangeRequestDomainInput}
          requestDomainValue={requestDomainValue}
          isSearch={isSearch}
          foucsInputRef={foucsInputRef}
        />
      </form>
      <form onSubmit={handleOnDomainfoNext}>
        <Divider />
        <RequestIPPointing
          formData={formData}
          data={data}
          errors={errors}
          handleOnChangeInput={handleOnChangeInput}
          handleOnClickDNSService={toggleDNSService}
          handleClickOnAddAndRemove={handleClickOnAddAndRemove}
          responeDomain={responeDomain}
        />
        <Divider />
        <ServiceTypeCharges
          formData={formData}
          errors={errors}
          handleClickOnServiceType={handleClickOnServiceType}
          handleChangeOnPicker={handleChangeOnPicker}
          handleClickOnFees={handleClickOnFees}
        />
        <Divider />
        <TermsAndConditions
          formData={formData}
          handleOnChangeInput={handleOnChangeInput}
        />
        <Divider />
        <div className="w-60 mt-8 mb-11 ml-auto">
          <Button
            type="submit"
            txtColor="text-white"
            bgColor={`${
              (nextStap === 0 && !data.length) ||
              typeof formData.radioBoxIPPointing === "undefined" ||
              (typeof formData.selectedActivationDate === "string" &&
                typeof formData.selectedRenewalDate === "string") ||
              !formData.checkBoxTermsConditions
                ? "bg-blue-400 cursor-not-allowed"
                : "bg-blue-500"
            }`}
            text="Next"
            nextStap={nextStap}
            formData={formData}
          />
        </div>
      </form>
    </div>
  );
};

export default DomainNameInfo;
