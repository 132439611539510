import React from "react";

//components
import DropDownBox from "./drop_down";
import ErrorMessage from "../MessageTexts/error_message.js";
import { COUNTRY_CODE } from "../../constants/countryCode.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const FloatingInputField = ({
  indx = -1,
  type = "",
  id = "",
  name = "",
  placeholder = "",
  placeHolderText = "",
  isRequired = false,
  isReadOnly = false,
  isDisabled = false,
  isAutofocus = false,
  dataValue = "",
  errorMessage = "",
  helpText = "",
  descriptiveText = "",
  isPhoneInput = false,
  dropDownName = "",
  selectedDropDownValue = "",
  isSearchInput = false,
  setClickSearch,
  /**
   * action
   */
  handleOnChangeInput,
  foucsInputRef,
}) => {
  return (
    <>
      {isPhoneInput ? (
        <div className="grid grid-cols-5 gap-x-2">
          <div className="hidden md:block md:col-span-2 md:mt-3">
            <p className="body-font">{placeHolderText}</p>
          </div>
          <div className="col-span-5 md:col-span-3">
            <div className="grid grid-cols-6 gap-x-1">
              <div className="col-span-2">
                <DropDownBox
                  name={dropDownName}
                  dataValue={COUNTRY_CODE}
                  selectedValue={selectedDropDownValue}
                  // isDisabled={formData.checkBoxBillingInfo}
                  isDisabled={true}
                  /**
                   * action
                   */
                  handleOnChangeSelectBox={handleOnChangeInput}
                />
              </div>
              <div className="col-span-4">
                <div className="relative group md">
                  <input
                    type={type}
                    id={indx >= 0 ? `${name}-${indx}` : name}
                    name={indx >= 0 ? `${name}-${indx}` : name}
                    required={isRequired}
                    disabled={isDisabled}
                    autoFocus={isAutofocus}
                    readOnly={isReadOnly}
                    value={dataValue}
                    autoComplete="off"
                    className="w-full h-auto shadow-sm pl-5 pr-12 py-4 md:py-3 primary-font peer bg-white text-black border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-[#003FDA] focus:shadow-md"
                    /**
                     * action
                     */
                    placeholder={placeholder}
                    onChange={(e) => handleOnChangeInput(e)}
                  />
                  <label
                    htmlFor={indx >= 0 ? `${name}-${indx}` : name}
                    className={`md:hidden text-sm text-slate-400 w-auto h-auto transform transition-all absolute top-5 md:top-3 left-2 flex items-center px-2 group-focus-within:bg-white group-focus-within:rounded-md group-focus-within:t-color-primary group-focus-within:caption-font group-focus-within:h-1/2 group-focus-within:-translate-y-full cursor-text ${
                      dataValue &&
                      "-translate-y-full bg-white h-1/2 caption-font"
                    } peer-valid:-translate-y-full peer-valid:bg-white peer-valid:h-1/2 peer-valid:caption-font`}
                  >
                    {placeHolderText}
                  </label>
                </div>
              </div>
            </div>
            {descriptiveText && (
              <p className="text-green-400">
                <small>{descriptiveText}</small>
              </p>
            )}
            {dataValue
              ? errorMessage && <ErrorMessage text={errorMessage} />
              : helpText && (
                  <p className="text-teal-400">
                    <small>({helpText})</small>
                  </p>
                )}
          </div>
        </div>
      ) : (
        <div className="w-full h-auto md:grid md:grid-cols-5 md:gap-x-2">
          <div className="hidden md:block md:col-span-2 md:mt-3">
            <p className="body-font">{placeHolderText}</p>
          </div>
          <div className="relative group md:col-span-3">
            <div className="flex">
              <input
                ref={foucsInputRef}
                type={type}
                id={indx >= 0 ? `${name}-${indx}` : name}
                name={indx >= 0 ? `${name}-${indx}` : name}
                required={isRequired}
                disabled={isDisabled}
                autoFocus={isAutofocus}
                readOnly={isReadOnly}
                value={dataValue}
                placeholder={placeholder}
                autoComplete="off"
                className={`w-full h-auto shadow-sm pl-5 pr-12 py-4 md:py-3 primary-font peer bg-white text-black border border-gray-200 ${
                  isSearchInput ? "rounded-l-lg" : "rounded-md"
                }  focus:outline-none focus:ring-1 focus:ring-[#003FDA] focus:shadow-md`}
                /**
                 * action
                 */
                onChange={(e) => handleOnChangeInput(e)}
              />
              {isSearchInput && (
                <button
                  type="submit"
                  onClick={setClickSearch}
                  className="flex justify-center items-center flex-col p-4 px-7 bg-[#003FDA] rounded-r-lg cursor-pointer"
                >
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    color="#fff"
                    className="text-lg"
                  />
                </button>
              )}
            </div>
            {placeHolderText && (
              <label
                htmlFor={indx >= 0 ? `${name}-${indx}` : name}
                className={`md:hidden text-sm text-slate-400 w-auto h-auto transform transition-all absolute top-5 md:top-3 left-2 flex items-center px-2 group-focus-within:bg-white group-focus-within:rounded-md group-focus-within:t-color-primary group-focus-within:caption-font group-focus-within:h-1/2 group-focus-within:-translate-y-full cursor-text ${
                  dataValue && "-translate-y-full bg-white h-1/2 caption-font"
                } peer-valid:-translate-y-full peer-valid:bg-white peer-valid:h-1/2 peer-valid:caption-font`}
              >
                {placeHolderText}
              </label>
            )}
            {descriptiveText && (
              <p className="text-green-400">
                <small>{descriptiveText}</small>
              </p>
            )}
            {dataValue
              ? errorMessage && <ErrorMessage text={errorMessage} />
              : helpText && (
                  <p className="text-teal-400">
                    <small>({helpText})</small>
                  </p>
                )}
          </div>
        </div>
      )}
    </>
  );
};

export default FloatingInputField;
