import React from "react";

// Define a functional component named 'FormatServiceDate' for formatting service dates
const FormatServiceDate = ({ dateTime, serviceDateCalculator }) => {
  return (
    <div className="inline-block">
      <span>{dateTime?.toDateString()}</span>
      <span> - </span>
      <span>{serviceDateCalculator(dateTime)}</span>
    </div>
  );
};

export default FormatServiceDate;
