import React from "react";
import ConfirmLoading from "../../ControlledComponents/Loading/confirmLoading";

const Loading = () => {
  return (
    <div className="h-screen w-full fixed top-0 left-0 flex justify-center items-center flex-col bg-black/70 z-50">
      <ConfirmLoading />
    </div>
  );
};

export default Loading;
