import React from "react";

//components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";

const Card = ({ Icon, Title, isChecked = false, List }) => {
  return (
    <div className="p-6 bg-gray-50 text-black rounded-lg shadow-md hover:bg-indigo-200 hover:shadow-lg">
      <div className="flex justify-between mb-5">
        <FontAwesomeIcon icon={Icon} className="text-4xl text-blue-700" />
        {isChecked && (
          <FontAwesomeIcon
            icon={faLightbulb}
            className="text-xl text-green-500"
          />
        )}
      </div>
      <h3 className="text-lg font-bold mb-2">{Title}</h3>
      {List}
    </div>
  );
};

export default Card;
