import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";

//components
import RegistrationPage from "./components/Registration";
import ConfirmationPage from "./components/Confirmation";
import RegistrationSuccessPage from "./components/RegistrationSuccess";
import {
  DEFAULT_ROUTE,
  CONFIRMATION_ROUTE,
  REGISTRATION_SUCCESS_ROUTE,
} from "./constants/routePathName";

const Router = () => {
  return (
    <Routes>
      <Route path={DEFAULT_ROUTE} element={<RegistrationPage />} />
      <Route path={CONFIRMATION_ROUTE} element={<ConfirmationPage />} />
      <Route
        path={REGISTRATION_SUCCESS_ROUTE}
        element={<RegistrationSuccessPage />}
      />
    </Routes>
  );
};

export default Router;
