import React from "react";

const DropDownBox = ({
  name = "",
  dataValue = [],
  selectedValue = "",
  isDisabled = false,
  /**
   * action
   */
  handleOnChangeSelectBox,
}) => {
  return (
    <>
      <select
        name={name}
        value={selectedValue}
        disabled={isDisabled}
        className="w-full h-full border border-gray-300 rounded-lg pl-2 pr-5 cursor-pointer"
        /**
         * action
         */
        onChange={handleOnChangeSelectBox}
      >
        {dataValue.map((v, index) => (
          <option key={index} value={v.dial_code}>
            {v.dial_code}
          </option>
        ))}
      </select>
    </>
  );
};

export default DropDownBox;
