import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faXmark,
  faCartPlus,
  faArrowRight,
  faCirclePlus,
  faCircleMinus,
} from "@fortawesome/free-solid-svg-icons";
import Hook from "./hook.js";
import moneyFormatter from "../../utils/moneyFormatter.js";
import { SyncLoader } from "react-spinners";
import Respone from "./Respone.js";
import CustomDomain from "./CustomDomain.js";

const Priority = ({ domainName, isSearch, fee, unit, customDomain }) => {
  const [handleOnToggleCart, data, responeDomain, isLoading, isDomainError] =
    Hook();
  return (
    <div
      className={`w-fulls ml-auto flex justify-between ${
        isSearch ? "h-auto" : "h-0"
      } transition-all ease-in-out overflow-hidden`}
    >
      {isLoading ? (
        <div className="p-3 flex justify-center items-center w-full mt-4">
          <SyncLoader
            color="#3b82f6"
            margin={2}
            size={13}
            speedMultiplier={1}
          />
        </div>
      ) : (
        <>
          <p className="body-font mt-4 hidden md:block">
            {!isDomainError && (
              <>
                <span className="text-red-500">**</span>&nbsp; Search Result{" "}
              </>
            )}
          </p>

          <div className="flex flex-col basis-full md:basis-[62%]">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full">
                    <tbody className="flex flex-col">
                      {isDomainError && (
                        <tr className="flex justify-center">
                          <td className="text-red-500 text-lg">
                            Sorry{" "}
                            <span className="text-blue-500">
                              "{isDomainError}"
                            </span>{" "}
                            is unavailable
                          </td>
                        </tr>
                      )}
                      {!isDomainError &&
                        responeDomain.map((item, index) => (
                          <Respone
                            data={data}
                            item={item}
                            unit={unit}
                            fee={fee}
                            handleOnToggleCart={handleOnToggleCart}
                            key={index}
                          />
                        ))}

                      {!isDomainError.length &&
                        customDomain.length > 0 &&
                        customDomain.map((item, index) => (
                          <CustomDomain
                            item={item}
                            handleOnToggleCart={handleOnToggleCart}
                            data={data}
                            key={index}
                          />
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Priority;
