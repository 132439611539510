import React, { useCallback, useEffect, useState } from "react";

const Hook = () => {
  // State variables using the 'useState' hook
  const [data, setData] = useState(
    // Initializing 'data' state with the parsed value from localStorage or an empty array if no data is present
    JSON.parse(localStorage.getItem("domainData")) || []
  );
  const [isDomainError, setIsDomainError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [requestDomain, setRequestDomain] = useState([]);
  const [responeDomain, setResponeDomain] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestMoreDomainLoading, setIsRequestMoreDomainLoading] =
    useState(false);

  // Callback function 'filterDomain' definition
  const filterDomain = useCallback(() => {
    // Checking if 'data' array has elements
    if (data.length) {
      // Using a Set to get unique domain names from the 'data' array
      const uniqueDomainNames = new Set(
        data.map((domain) => domain.domainName)
      );

      // Creating a new array with only one entry per unique domain name
      const filteredData = Array.from(uniqueDomainNames).map((domainName) =>
        data.find((domain) => domain.domainName === domainName)
      );

      // Updating the 'data' state with the filtered data
      setData(filteredData);

      // Updating the 'domainData' item in localStorage with the filtered data
      localStorage.setItem("domainData", JSON.stringify(filteredData));
    }

    // Saving the current 'data' state to localStorage even if no filtering is done
    localStorage.setItem("domainData", JSON.stringify(data));
  }, []); // Dependency array includes 'data' to ensure that the function has access to the latest 'data' state

  // useEffect hook to trigger the 'filterDomain' function when the component mounts or 'data' state changes
  useEffect(() => {
    filterDomain();
  }, [filterDomain]); // Dependency array includes 'filterDomain' to ensure useEffect runs when 'filterDomain' changes
  useEffect(() => {
    localStorage.setItem("domainData", JSON.stringify(data));
  }, [data]);
  // Returning an array containing state-setting functions and the current state values
  return [
    setData,
    setIsOpen,
    setRequestDomain,
    setResponeDomain,
    setIsLoading,
    setIsRequestMoreDomainLoading,
    setIsDomainError,
    data,
    isOpen,
    requestDomain,
    responeDomain,
    isLoading,
    isRequestMoreDomainLoading,
    isDomainError,
  ];
};

export default Hook;
