import React from "react";
import { GridLoader } from "react-spinners";

const ConfirmLoading = () => {
  return (
    <div>
      <GridLoader color="#3b82f6" />
    </div>
  );
};

export default ConfirmLoading;
