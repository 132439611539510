import React from "react";
import moneyFormatter from "../../utils/moneyFormatter";

const Badge = ({
  isSelected,
  labelName,
  unit,
  /**
   * action
   */
  clickOnBadge,
}) => {
  return (
    <div
      className={`border rounded-full py-1 px-4 ${
        isSelected ? "bg-blue-500" : "bg-gray-200"
      } duration-300 cursor-pointer`}
      /**
       * action
       */
      onClick={clickOnBadge}
    >
      <div>
        <p className={`body-font ${isSelected ? "text-white" : "text-black"}`}>
          {moneyFormatter(labelName)} {unit}
        </p>
      </div>
    </div>
  );
};

export default Badge;
