// Import necessary React hooks and components
import { useNavigate, useLocation } from "react-router-dom";
import {
  DEFAULT_ROUTE,
  REGISTRATION_SUCCESS_ROUTE,
} from "../../constants/routePathName.js";
import composePostData from "../../utils/composePostData.js";
import masterDataProps from "../../utils/masterDataProp.js";
import { useContext, useEffect, useState } from "react";
import axios from "axios";

// Define a custom React Hook named 'Hook'
export function Hook() {
  // Destructure values from the masterDataProps context
  const [
    setData,
    setIsOpen,
    setRequestDomain,
    setResponeDomain,
    setIsLoading,
    setIsRequestMoreDomainLoading,
    setIsDomainError,
    data,
    isOpen,
    requestDomain,
    responeDomain,
    isLoading,
    isRequestMoreDomainLoading,
    isDomainError,
  ] = useContext(masterDataProps);

  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  // Get navigation and location from react-router-dom
  const navigation = useNavigate();
  const location = useLocation();

  // Extract formData from the location state
  const formData = location?.state;

  // Function to handle cancel button click
  const handleClickOnCancel = () => {
    navigation(DEFAULT_ROUTE, {
      state: formData,
    });
  };

  // Function to calculate the total service date
  const serviceCalculator = (dateTime) => {
    // Convert dateTime to a Date object
    const totalDate = new Date(dateTime);

    // Calculate the date after adding one year
    const calcYear = new Date(totalDate).setFullYear(
      dateTime.getFullYear() + 1
    );

    // Create a new Date object for the calculated year
    const yearResult = new Date(calcYear);

    // Calculate the date after subtracting one day
    const calcDay = new Date(yearResult).setDate(yearResult.getDate() - 1);

    // Convert the final date to a string
    const totalServiceDate = new Date(calcDay).toDateString();

    return totalServiceDate;
  };

  // Function to handle confirmation button click
  const handleClickOnConfirm = (e) => {
    if (isConfirmLoading) {
      return;
    }
    setIsConfirmLoading(true);

    // Prepare data for the API request
    const purchesData = {
      domains: data,
      dns_option: formData.DNSFieldArray,
      appAddress: formData.appAddress,
      appEmail: formData.appContactEmail,
      appContactName: formData.appContactName,
      appPhone: `${formData.appContactCode + formData.appContactPhone}`,
      representName: formData.representName,
      nrcNumber: formData.nrcPassNumber,
      companyName: formData.companyName,
      companyRegisterNumber: formData.companyRegisterNumber,
      techContactName: formData.techContactName,
      techContactEmail: formData.techContactEmail,
      techContactPhone: `${
        formData.techContactCode + formData.techContactPhone
      }`,
      billContactName: formData.billContactName,
      billContactEmail: formData.billContactEmail,
      billContactPhone: `${
        formData.billContactCode + formData.billContactPhone
      }`,
      billOfficePhone: `${formData.billOfficeCode + formData.billOfficePhone}`,
      billAddress: formData.billAddress,
      radioBoxIPPointing: formData.radioBoxIPPointing,
      serviceType: formData.selectedServiceType,
      selectedActivationDate: formData.selectedActivationDate,
      selectedActivationFee: formData.selectedActivationFee,
      selectedRenewalDate: formData.selectedRenewalDate,
      selectedRenewalFee: formData.selectedRenewalFee,
      selectedType: formData.selectedServiceType.id,
    };
    // Make an API request using axios
    return axios
      .post(`${process.env.REACT_APP_API_URL}/register`, purchesData)
      .then((res) => {
        if (res.status === 200) {
          setIsConfirmLoading(false);
          // Clear the data and navigate to the success route
          navigation(DEFAULT_ROUTE, {
            state: "",
          });
          setData([]);
          return navigation(REGISTRATION_SUCCESS_ROUTE);
        }
      })
      .catch((err) => {
        return;
      });
  };

  // Return an array containing necessary values and functions
  return [
    formData,
    data,
    isConfirmLoading,
    // Action functions
    handleClickOnConfirm,
    handleClickOnCancel,
    // Service calculator function
    serviceCalculator,
  ];
}
