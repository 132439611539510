import React from "react";
import {
  faAngleUp,
  faChevronDown,
  faCircleMinus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Hook from "./hook";
import moneyFormatter from "../../utils/moneyFormatter";
import { useLocation } from "react-router-dom";
import {
  CONFIRMATION_ROUTE,
  REGISTRATION_SUCCESS_ROUTE,
} from "../../constants/routePathName";

// Define the Cart component
const Cart = () => {
  // Destructure values from the Hook component
  const [
    handleOnOpenCart,
    handleOnToggleCart,
    handleOnMouseLeave,
    handleOnMouseOver,
    isOpen,
    total,
    totalDataCartWidth,
    cartRef,
    data,
  ] = Hook();

  // Get the current location using useLocation from react-router-dom
  const location = useLocation();

  // Return the JSX for the Cart component
  return (
    <div
      className={`${
        (location.pathname === CONFIRMATION_ROUTE ||
          location.pathname === REGISTRATION_SUCCESS_ROUTE) &&
        "hidden"
      } block w-full px-4 md:px-0 md:w-max bg-white fixed bottom-0 right-0 z-50 transition-all ease-in-out duration-200 ${
        !isOpen ? "h-11" : "h-96"
      }`}
      onMouseLeave={handleOnMouseLeave}
      onMouseOver={handleOnMouseOver}
    >
      <div className="relative h-full border-x">
        {/* Cart Header */}
        <div
          className={`p-3 w-full rounded-t flex justify-between items-center cursor-pointer ${
            data.length === 0
              ? "bg-blue-400 cursor-not-allowed"
              : "bg-blue-500 cursor-pointer"
          }`}
          onClick={handleOnOpenCart}
        >
          <FontAwesomeIcon
            icon={!isOpen ? faAngleUp : faChevronDown}
            color="#fff"
            className="text-xl mx-2 mr-3"
          />
          <span className="text-white mr-auto">{data.length} domains</span>
          <div className="mx-2 flex justify-center items-center gap-x-2">
            <span className="text-white ">Total:</span>
            <span className="text-white font-semibold">
              {!data.length ? 0 : total} MMK
            </span>
          </div>
        </div>
        {/* Cart Body */}
        <div className="w-full bg-white overflow-auto h-64">
          <div className="relative">
            {/* Cart Table */}
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr ref={cartRef}>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Domain Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Fees
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((domainData, index) => (
                  <tr
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    key={index}
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {/* Remove domain from the cart */}
                      <FontAwesomeIcon
                        icon={faCircleMinus}
                        onClick={() =>
                          handleOnToggleCart({
                            domainName: domainData.domainName,
                          })
                        }
                        className="text-gray-600 cursor-pointer"
                        size="lg"
                      />
                    </th>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {domainData.domainName}
                    </th>
                    <td className="px-6 py-4">
                      {moneyFormatter(domainData.domainFee)} MMK
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Cart Footer */}
          <div
            className={`${
              !isOpen ? "invisible opacity-0" : "visible opacity-100"
            } z-10 flex flex-col justify-center items-center border-t pt-3 mt-auto absolute left-0 bottom-0 w-full pb-3 transition-all duration-200 ease-in-out bg-white`}
          >
            <div className="flex justify-between items-center w-full px-4">
              <div>{data.length} domains</div>
              <div className="text-base text-center">
                Total :
                <span className="font-bold">{moneyFormatter(total)} MMK</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
