import React, { useContext, useState } from "react";
import Input from "../Inputs/input_field.js";
import masterDataProps from "../../utils/masterDataProp.js";
import { SyncLoader } from "react-spinners";

const RequestDomain = ({
  handleOnChangeRequestDomainInput,
  requestDomainValue,
  handleOnRequestMoreDomain,
  isSearch,
  foucsInputRef,
  domainName,
}) => {
  const [
    setData,
    setIsOpen,
    setRequestDomain,
    setResponeDomain,
    setIsLoading,
    setIsRequestMoreDomainLoading,
    setIsDomainError,
    data,
    isOpen,
    requestDomain,
    responeDomain,
    isLoading,
    isRequestMoreDomainLoading,
    isDomainError,
  ] = useContext(masterDataProps);
  return (
    !isDomainError && (
      <div className="flex jusify-center gap-y-2 cursor-pointer flex-col md:w-[60%] ml-8 md:ml-0 w-full">
        {isRequestMoreDomainLoading && (
          <div className="p-3 flex justify-center items-center w-full mt-4">
            <SyncLoader
              color="#3b82f6"
              margin={2}
              size={13}
              speedMultiplier={1}
            />
          </div>
        )}
        {isSearch && !isLoading && !isRequestMoreDomainLoading && (
          <Input
            inputClass="w-full mx-auto"
            domainName={domainName.trim().toLowerCase()}
            isSearch={true}
            dataValue={requestDomainValue
              .trim()
              .toLowerCase()
              .replace(/[^\w\s.]/g, "")}
            handleOnChangeInput={handleOnChangeRequestDomainInput}
            handleOnRequestMoreDomain={handleOnRequestMoreDomain}
          />
        )}
      </div>
    )
  );
};

export default RequestDomain;
