// import logo from "./logo.svg";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";

import Routing from "./Router.js";
import Cart from "./components/DomainCart/index.js";
import masterDataProps from "./utils/masterDataProp.js";
import Hook from "./ControlledComponents/App/hook.js";

function App() {
  const [
    setData,
    setIsOpen,
    setRequestDomain,
    setResponeDomain,
    setIsLoading,
    setIsRequestMoreDomainLoading,
    setIsDomainError,
    data,
    isOpen,
    requestDomain,
    responeDomain,
    isLoading,
    isRequestMoreDomainLoading,
    isDomainError,
  ] = Hook();

  return (
    <masterDataProps.Provider
      value={[
        setData,
        setIsOpen,
        setRequestDomain,
        setResponeDomain,
        setIsLoading,
        setIsRequestMoreDomainLoading,
        setIsDomainError,
        data,
        isOpen,
        requestDomain,
        responeDomain,
        isLoading,
        isRequestMoreDomainLoading,
        isDomainError,
      ]}
    >
      <Router>
        <Routing />
        <Cart />
      </Router>
    </masterDataProps.Provider>
  );
}

export default App;
