import { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//components
import {
  TYPE_OF_DOMAIN,
  TYPE_OF_SERVICE,
  LIST_OF_ACTIVATION_FEES,
  LIST_OF_RENEWAL_FEES,
  DNS_FIELD,
} from "../../constants/constantData.js";
import { ACTIVATION, RENEWAL } from "../../constants/fieldType.js";
import {
  checkIPAddress,
  checkEmailAddress,
  checkPhoneNumber,
  checkErrorForAllFormData,
} from "./util.js";
import { CONFIRMATION_ROUTE } from "../../constants/routePathName.js";
import masterDataProps from "../../utils/masterDataProp.js";
import axios from "axios";

export function Hook() {
  const [
    setData,
    setIsOpen,
    setRequestDomain,
    setResponeDomain,
    setIsLoading,
    setIsRequestMoreDomainLoading,
    setIsDomainError,
    data,
    isOpen,
    requestDomain,
    responeDomain,
    isLoading,
    isRequestMoreDomainLoading,
    isDomainError,
  ] = useContext(masterDataProps);
  const navigation = useNavigate();
  const location = useLocation();
  const formStateData = location?.state;
  /** State */

  const [isOther, setIsOther] = useState(false);
  const [otherDomainType, setOtherDomainType] = useState("");
  const [requestDomainValue, setRequestDomainValue] = useState("");
  const [priority, setPriority] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [customDomain, setCustomDomain] = useState([]);
  const [nextStap, setNextStap] = useState(0);
  const [isFetchError, setIsFetchError] = useState(false);
  const [isRequestMoreDomainFetchError, setIsRequestMoreDomainFetchError] =
    useState(false);
  const [isRegisterError, setIsRegisterError] = useState(false);
  const [sessionDomain, setSessionDomain] = useState();

  const defaultState = {
    domainName: "".trim().toLocaleLowerCase(),
    domainTypeName: TYPE_OF_DOMAIN[0].typeName,
    selectedDomainType: TYPE_OF_DOMAIN[0],
    radioBoxIPPointing: undefined,
    DNSFieldArray: [DNS_FIELD],
    representName: "",
    nrcPassNumber: "",
    companyName: "",
    companyRegisterNumber: "",
    appContactName: "",
    appContactEmail: "",
    appContactCode: "+95",
    appContactPhone: "",
    appAddress: "",
    checkBoxBillingInfo: false,
    billContactName: "",
    billContactEmail: "",
    billContactCode: "+95",
    billContactPhone: "",
    billOfficeCode: "+95",
    billOfficePhone: "",
    billAddress: "",
    checkBoxTechInfo: false,
    techContactName: "",
    techContactEmail: "",
    techContactCode: "+95",
    techContactPhone: "",
    selectedServiceType: TYPE_OF_SERVICE[0],
    selectedActivationDate: "",
    selectedActivationFee: LIST_OF_ACTIVATION_FEES[0],
    selectedRenewalDate: "",
    selectedRenewalFee: LIST_OF_RENEWAL_FEES[0],
    checkBoxTermsConditions: false,
  };
  const defaultErrors = {
    selfDomainManagement: "",
    hostIPAddress: [""],
    appContactEmail: "",
    appContactPhone: "",
    billContactEmail: "",
    billContactPhone: "",
    billOfficePhone: "",
    techContactEmail: "",
    techContactPhone: "",
    selectedActivationDate: "",
    selectedRenewalDate: "",
  };
  const [formData, setFormData] = useState(
    formStateData && data.length ? formStateData : defaultState
  );
  const [errors, setErrors] = useState(defaultErrors);

  const toggleBillingInfo = useCallback(() => {
    if (formData.checkBoxBillingInfo) {
      setFormData({
        ...formData,
        billContactName: formData.appContactName,
        billContactEmail: formData.appContactEmail,
        billContactCode: formData.appContactCode,
        billContactPhone: formData.appContactPhone,
        billAddress: formData.appAddress,
      });
    } else {
      setFormData({
        ...formData,
        billContactName: "",
        billContactEmail: "",
        billContactCode: "+95",
        billContactPhone: "",
        billAddress: "",
      });
    }
  }, [formData.checkBoxBillingInfo]);

  /**
   * Life Cycle
   */
  useEffect(() => {
    toggleBillingInfo();
  }, [formData.checkBoxBillingInfo]);

  useEffect(() => {
    if (requestDomain.length && isFetchError) {
      setRequestDomain([]);
      handleOpenDomainBox();
      setIsFetchError(false);
    }
  }, [isFetchError, setIsFetchError, requestDomain]);

  useEffect(() => {
    if (isRequestMoreDomainFetchError) {
      handleOnRequestMoreDomain();
    }
  }, [isRequestMoreDomainFetchError]);

  useEffect(() => {
    if (isRegisterError) {
      setTimeout(() => {
        setIsRegisterError(false);
      }, 5000);
    }
  }, [isRegisterError]);

  useEffect(() => {
    if (formData.checkBoxTechInfo) {
      setFormData({
        ...formData,
        techContactName: formData.appContactName,
        techContactEmail: formData.appContactEmail,
        techContactCode: formData.appContactCode,
        techContactPhone: formData.appContactPhone,
      });
    } else {
      setFormData({
        ...formData,
        techContactName: "",
        techContactEmail: "",
        techContactCode: "+95",
        techContactPhone: "",
      });
    }
  }, [formData.checkBoxTechInfo]);

  const handleOnChangeInput = (event) => {
    const target = event.target;
    let tempFormData = formData;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    // Dynamic Input Field - sub domain name and host ip address
    let indx = name.split("-")[1];
    if (indx) {
      switch (name) {
        case `${Object.keys(DNS_FIELD)[0]}-${indx}`:
          tempFormData.DNSFieldArray[indx] = {
            ...tempFormData.DNSFieldArray[indx],
            subDomainName: value,
          };
          break;
        case `${Object.keys(DNS_FIELD)[1]}-${indx}`:
          tempFormData.DNSFieldArray[indx] = {
            ...tempFormData.DNSFieldArray[indx],
            hostIPAddress: value,
          };
        default:
          break;
      }
    }

    if (tempFormData.checkBoxBillingInfo) {
      switch (name) {
        case "appContactName":
          tempFormData = {
            ...tempFormData,
            billContactName: value,
          };
          break;
        case "appContactEmail":
          tempFormData = {
            ...tempFormData,
            billContactEmail: value,
          };
          break;
        case "appContactCode":
          tempFormData = {
            ...tempFormData,
            billContactCode: value,
          };
          break;
        case "appContactPhone":
          tempFormData = {
            ...tempFormData,
            billContactPhone: value,
          };
          break;
        case "appAddress":
          tempFormData = {
            ...tempFormData,
            billAddress: value,
          };
          break;
        default:
          break;
      }
    }
    if (tempFormData.checkBoxTechInfo) {
      switch (name) {
        case "appContactName":
          tempFormData = {
            ...tempFormData,
            techContactName: value,
          };
          break;
        case "appContactEmail":
          tempFormData = {
            ...tempFormData,
            techContactEmail: value,
          };
          break;
        case "appContactCode":
          tempFormData = {
            ...tempFormData,
            techContactCode: value,
          };
          break;
        case "appContactPhone":
          tempFormData = {
            ...tempFormData,
            techContactPhone: value,
          };
          break;
        default:
          break;
      }
    }

    if (name === `hostIPAddress-${indx}`) {
      let temp_host_IP_error = errors.hostIPAddress;
      temp_host_IP_error[indx] = checkIPAddress(value);
      setErrors({
        ...errors,
        hostIPAddress: temp_host_IP_error,
      });
    }
    if (name === "appContactEmail") {
      setErrors({
        ...errors,
        appContactEmail: checkEmailAddress(value),
      });
    }
    if (name === "appContactPhone") {
      setErrors({
        ...errors,
        appContactPhone: checkPhoneNumber(value),
      });
    }
    if (name === "billContactEmail") {
      setErrors({
        ...errors,
        billContactEmail: checkEmailAddress(value),
      });
    }
    if (name === "billContactPhone") {
      setErrors({
        ...errors,
        billContactPhone: checkPhoneNumber(value),
      });
    }
    if (name === "billOfficePhone") {
      setErrors({
        ...errors,
        billOfficePhone: checkPhoneNumber(value),
      });
    }
    if (name === "techContactEmail") {
      setErrors({
        ...errors,
        techContactEmail: checkEmailAddress(value),
      });
    }
    if (name === "techContactPhone") {
      setErrors({
        ...errors,
        techContactPhone: checkPhoneNumber(value),
      });
    }

    tempFormData = {
      ...tempFormData,
      [name]: value,
    };
    setFormData(tempFormData);
  };

  const handleClickOnDomainType = (domainType) => {
    setIsOther(() => !isOther);
    if (domainType.id === 6) {
      setFormData({
        ...formData,
        domainTypeName: "",
        selectedDomainType: domainType,
      });
      return;
    }

    setFormData({
      ...formData,
      domainTypeName: domainType.typeName,
      selectedDomainType: domainType,
    });
  };

  const handleClickOnServiceType = (serviceType) => {
    setFormData({
      ...formData,
      selectedServiceType: serviceType,
    });
  };

  const toggleDNSService = (value) => {
    setFormData({
      ...formData,
      radioBoxIPPointing: value,
    });
  };

  const handleClickOnAddAndRemove = (btn_type, index) => {
    let temp_field_arr = formData.DNSFieldArray;
    let temp_host_IP_error_arr = errors.hostIPAddress;
    const length = temp_field_arr.length;

    switch (btn_type) {
      case "add":
        if (length <= 2) {
          temp_field_arr.push(DNS_FIELD);
          temp_host_IP_error_arr.push("");
        }
        break;
      case "remove":
        if (length > 1) {
          temp_field_arr.splice(index, 1);
          temp_host_IP_error_arr.splice(index, 1);
        }
      default:
        break;
    }
    setErrors({
      ...errors,
      hostIPAddress: temp_host_IP_error_arr,
    });
    setFormData({
      ...formData,
      DNSFieldArray: temp_field_arr,
    });
  };

  const handleClickOnFees = (fee, field_type) => {
    switch (field_type) {
      case ACTIVATION:
        setFormData({
          ...formData,
          selectedActivationFee: fee,
        });
        break;
      case RENEWAL:
        setFormData({
          ...formData,
          selectedRenewalFee: fee,
        });
        break;

      default:
        break;
    }
  };

  const handleChangeOnPicker = (date, field_type) => {
    switch (field_type) {
      case ACTIVATION:
        setFormData({
          ...formData,
          selectedActivationDate: date,
        });
        break;
      case RENEWAL:
        setFormData({
          ...formData,
          selectedRenewalDate: date,
        });
        break;

      default:
        break;
    }
  };

  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (data.length < 1) return setIsRegisterError(true);
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (checkErrorForAllFormData(errors)) {
      return;
    }

    // For Self Domain Management - Radio Box
    if (formData.radioBoxIPPointing === undefined) {
      setErrors({
        ...errors,
        selfDomainManagement: "Please select at least one!",
      });
      return;
    }

    //For Service Type - New -MK
    if (
      !formData.selectedActivationDate &&
      formData.selectedServiceType.id === 1
    ) {
      setErrors({
        ...errors,
        selectedActivationDate: "Please fill out the Activation Date!",
      });
      return;
    }

    //For Service Type - Reopen - MK
    if (
      !formData.selectedRenewalDate &&
      formData.selectedServiceType.id === 2
    ) {
      setErrors({
        ...errors,
        selectedRenewalDate: "Please fill out the Renewal Date!",
      });
      return;
    }
    navigation(CONFIRMATION_ROUTE, {
      state: formData,
    });
  };
  const onReset = () => {
    setFormData(defaultState);
    setErrors(defaultErrors);
  };

  const handleOnChangeRequestDomainInput = (event) => {
    const { value } = event.target;
    return setRequestDomainValue(value.replace(/[^\w\s.-]/g, ""));
  };
  const handleOnRequestMoreDomain = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (isLoading) return;
    let ifDomainAlreadyExist;
    let ifOtherDomainAlreadyExist;
    customDomain.find(
      (item) =>
        (ifOtherDomainAlreadyExist =
          item.domainName ===
          responeDomain[0].domainname.split(".")[0] +
            requestDomain +
            "." +
            requestDomainValue
              .trim()
              .toLowerCase()
              .replace(/\.*$/, "")
              .replace(/\.{2,}/g, ".") +
            "." +
            "mm")
    );

    responeDomain.find(
      (domain) =>
        (ifDomainAlreadyExist =
          domain.domainname ===
          domain.domainname.split(".")[0] +
            "." +
            requestDomainValue
              .trim()
              .toLowerCase()
              .replace(/\.*$/, "")
              .replace(/\.{2,}/g, ".") +
            "." +
            "mm")
    );
    if (requestDomainValue.trim()) {
      if (!ifOtherDomainAlreadyExist && !ifDomainAlreadyExist) {
        setIsRequestMoreDomainLoading(true);
        axios
          .post(`${process.env.REACT_APP_API_URL}/get-domain`, {
            domains: JSON.stringify([
              responeDomain[0].domainname.split(".")[0] +
                requestDomain +
                "." +
                requestDomainValue
                  .trim()
                  .toLowerCase()
                  .replace(/\.*$/, "")
                  .replace(/\.{2,}/g, ".") +
                "." +
                "mm",
            ]),
          })
          .then((res) => {
            setCustomDomain((prev) => {
              return [
                ...prev,
                {
                  domainName: res.data.data[0].domainname,
                  status: res.data.data[0].status,
                  fee: "50000",
                  unit: "MMK",
                },
              ];
            });
            setRequestDomainValue("");
            setIsRequestMoreDomainFetchError(false);
            setIsRequestMoreDomainLoading(false);
          })
          .catch((err) => {
            setIsRequestMoreDomainFetchError(true);
            setIsRequestMoreDomainLoading(true);
          });
      }
    }

    return;
  };

  const handleOnDomainfoNext = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!data.length) {
      return setIsRegisterError(true);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsFetchError(false);
    return setNextStap(() => nextStap + 1);
  };

  const handleOnDomainPrev = () => {
    return setNextStap(() => nextStap - 1);
  };
  const handleOpenDomainBox = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (
      /^[^\w\s]/.test(formData.domainName.charAt(0)) ||
      !formData.domainName.length ||
      isLoading
    ) {
      return;
    }
    let localResponeDomain = [];
    let formDataDomainValue = formData.domainName
      .replace(/\.*$/, "")
      .replace(/\.{2,}/g, ".");

    setRequestDomain([]);
    setCustomDomain([]);
    setRequestDomainValue("");
    setIsSearch(true);
    setIsLoading(true);
    setPriority(formData.domainName.trim().toLowerCase());
    let domainCustomSplitType;

    TYPE_OF_DOMAIN.find(
      (domainName) =>
        (domainCustomSplitType = formDataDomainValue && domainName.typeName)
    );

    if (domainCustomSplitType) {
      if (formDataDomainValue.split(".").length !== 1) {
        localResponeDomain.push(formDataDomainValue);
      }
    } else {
      formDataDomainValue = "";
    }
    setIsDomainError("");
    for (let i = 0; i < TYPE_OF_DOMAIN.length; i++) {
      const domainType = TYPE_OF_DOMAIN[i];

      if (
        formDataDomainValue.split(".").length === 2 &&
        formDataDomainValue.split(".")[1] + ".mm" === domainType.typeName
      ) {
        let firstDomain;

        firstDomain = formDataDomainValue + "." + "mm";

        localResponeDomain.splice(1, 0, firstDomain);
      }
      localResponeDomain.push(
        formDataDomainValue.split(".")[0] + "." + domainType.typeName
      );
    }
    setRequestDomain(localResponeDomain);
  };

  useEffect(() => {
    if (requestDomain.length >= 1) {
      fetchSomething();
    }
  }, [requestDomain]);

  const fetchSomething = async () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/get-domain`,
        {
          domains: JSON.stringify(requestDomain),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          const data = res.data.data;
          // if (sessionDomain) {
          //   sessionDomain?.map((domain) => data.unshift(domain));
          //   setResponeDomain(data);
          // }
          setResponeDomain(data);

          requestDomain.splice(0, requestDomain.length);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setIsFetchError(true);
      });
  };

  return [
    formData,
    data,
    setFormData,
    errors,
    /**
     * action
     */
    handleOnChangeInput,
    handleClickOnDomainType,
    handleClickOnServiceType,
    handleClickOnAddAndRemove,
    handleChangeOnPicker,
    handleClickOnFees,
    handleOnChangeRequestDomainInput,
    handleOnRequestMoreDomain,
    handleOpenDomainBox,
    handleOnDomainfoNext,
    handleOnDomainPrev,
    /**
     * service
     */
    toggleDNSService,
    onSubmit,
    onReset,
    setOtherDomainType,
    isOther,
    isSearch,
    priority,
    otherDomainType,
    requestDomainValue,
    customDomain,
    nextStap,
    isRegisterError,
  ];
}
