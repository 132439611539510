import React, { useState } from "react";
import parse from 'html-react-parser';

//components
import SubHeader from "../../../ControlledComponents/Header/sub_header.js";
import CheckBox from "../../../ControlledComponents/Inputs/check_box.js";
import { LIST_OF_TERMS_CONDITIONS } from "./../../../constants/constantData";

const TermsAndConditions = ({
  formData,
  /**
   * action
   */
  handleOnChangeInput,
}) => {
  const [isShow, setIsShow] = useState(true);
  const [isMore, setIsMore] = useState(false);

  return (
    <div className="py-4">
      <SubHeader
        subHeaderName="Terms and Conditions"
        isShow={isShow}
        setIsShow={setIsShow}
      />
      {isShow && (
        <div className="w-full h-auto mt-2 md:mt-4 lg:mt-6">
          <ul className="list-disc list-outside space-y-3">
            {LIST_OF_TERMS_CONDITIONS.map((term) => (
              <li key={term.id}>
                <p className="body-text text-justify">{parse(term.text)}</p>
              </li>
            ))}
          </ul>
          <div className="mt-8 flex items-center space-x-6">
            <CheckBox
              name="checkBoxTermsConditions"
              isChecked={formData.checkBoxTermsConditions}
              isRequired={true}
              /**
               * action
               */
              handleOnChangeCheckBox={handleOnChangeInput}
            />
            <p
              className={`body-text ${
                formData.checkBoxTermsConditions
                  ? "text-black"
                  : "text-gray-400"
              } font-medium`}
            >
              I agree to all terms & conditions{" "}
              <span className="text-red-500"> ** </span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsAndConditions;
