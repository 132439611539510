import React, { useState } from "react";

//components
import SubHeader from "../../../ControlledComponents/Header/sub_header.js";
import FloatInput from "../../../ControlledComponents/Inputs/float_input_field";
import ErrorMessage from "../../../ControlledComponents/MessageTexts/error_message.js";
import TextArea from "../../../ControlledComponents/Inputs/text_area.js";

const ApplicantInfo = ({
  formData,
  errors,
  /**
   * action
   */
  handleOnChangeInput,
}) => {
  const [isShow, setIsShow] = useState(true);
  return (
    <div className="py-4">
      <SubHeader
        subHeaderName="Applicant Information"
        isShow={isShow}
        setIsShow={setIsShow}
      />
      {isShow && (
        <div className="pt-5 space-y-4">
          <div className="grid grid-cols-2 gap-y-6 lg:gap-x-3">
            <div className="col-span-2 lg:col-span-1">
              <FloatInput
                type="text"
                name="representName"
                placeHolderText={
                  <>
                    <span className="text-red-500">**</span> &nbsp;
                    Representative Name
                  </>
                }
                isRequired={true}
                dataValue={formData.representName}
                /**
                 * action
                 */
                handleOnChangeInput={handleOnChangeInput}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <FloatInput
                type="text"
                name="nrcPassNumber"
                placeHolderText={
                  <>
                    <span className="text-red-500">**</span> &nbsp; NRC (or)
                    Passport Number
                  </>
                }
                isRequired={true}
                dataValue={formData.nrcPassNumber}
                /**
                 * action
                 */
                handleOnChangeInput={handleOnChangeInput}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <FloatInput
                type="text"
                name="companyName"
                placeHolderText="Company's Name"
                dataValue={formData.companyName}
                /**
                 * action
                 */
                handleOnChangeInput={handleOnChangeInput}
              />
            </div>

            <div className="col-span-2 lg:col-span-1">
              <FloatInput
                type="text"
                name="companyRegisterNumber"
                placeHolderText="Company's Registration Number"
                dataValue={formData.companyRegisterNumber}
                /**
                 * action
                 */
                handleOnChangeInput={handleOnChangeInput}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <FloatInput
                type="text"
                name="appContactName"
                placeHolderText={
                  <>
                    <span className="text-red-500">**</span> &nbsp; Contact Name
                  </>
                }
                isRequired={true}
                dataValue={formData.appContactName}
                /**
                 * action
                 */
                handleOnChangeInput={handleOnChangeInput}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <FloatInput
                type="text"
                name="appContactEmail"
                placeHolderText={
                  <>
                    <span className="text-red-500">**</span> &nbsp; Contact
                    Email
                  </>
                }
                isRequired={true}
                dataValue={formData.appContactEmail}
                errorMessage={errors.appContactEmail}
                /**
                 * action
                 */
                handleOnChangeInput={handleOnChangeInput}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <FloatInput
                type="number"
                name="appContactPhone"
                placeHolderText={
                  <>
                    <span className="text-red-500">**</span> &nbsp; Contact
                    Phone Number
                  </>
                }
                isRequired={true}
                dataValue={formData.appContactPhone}
                errorMessage={errors.appContactPhone}
                isPhoneInput={true}
                dropDownName="appContactCode"
                selectedDropDownValue={formData.appContactCode}
                /**
                 * action
                 */
                handleOnChangeInput={handleOnChangeInput}
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <TextArea
                type="text"
                name="appAddress"
                placeHolderText={
                  <>
                    <span className="text-red-500">**</span> &nbsp; Address
                  </>
                }
                isRequired={true}
                dataValue={formData.appAddress}
                /**
                 * action
                 */
                handleOnChangeTextArea={handleOnChangeInput}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplicantInfo;
