import React, { useEffect, useRef, useState } from "react";

//components
import SubHeader from "../../../ControlledComponents/Header/sub_header.js";
import Badge from "../../../ControlledComponents/Badge/badge.js";
import DateTimePicker from "../../../ControlledComponents/Inputs/date_picker.js";
import {
  TYPE_OF_SERVICE,
  LIST_OF_ACTIVATION_FEES,
  LIST_OF_RENEWAL_FEES,
} from "../../../constants/constantData.js";
import { ACTIVATION, RENEWAL } from "../../../constants/fieldType.js";
import ErrorMessage from "../../../ControlledComponents/MessageTexts/error_message.js";

const ServiceTypeCharges = ({
  formData,
  errors,
  /**
   * action
   */
  handleClickOnServiceType,
  handleChangeOnPicker,
  handleClickOnFees,
}) => {
  const [isShow, setIsShow] = useState(true);
  const requireInput = useRef(null);
  useEffect(() => {
    if (
      typeof formData.selectedActivationDate === "string" &&
      typeof formData.selectedRenewalDate === "string"
    ) {
      requireInput.current.setAttribute("required", "required");
    } else {
      requireInput.current.removeAttribute("required");
    }
  }, [formData]);

  return (
    <div className="py-4 z-30">
      <SubHeader
        subHeaderName="Service Types and Charges"
        isShow={isShow}
        setIsShow={setIsShow}
      />
      {isShow && (
        <div className="pt-5 space-y-4 relative">
          <div className="grid grid-cols-5 gap-y-3">
            <div className="col-span-5 md:col-span-2">
              <p className="body-font md:mt-1">Service Detail Information</p>
            </div>
            <div className="col-span-5 md:col-span-3">
              <div className="flex items-center flex-wrap space-x-3">
                {TYPE_OF_SERVICE.map((serviceType) => (
                  <div key={serviceType.id} className="mb-1">
                    <Badge
                      isSelected={
                        formData.selectedServiceType.id === serviceType.id
                      }
                      labelName={serviceType.serviceName}
                      clickOnBadge={() => handleClickOnServiceType(serviceType)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center items-center flex-col absolute -left-10 z-0 opacity-0">
            <input type="text" required ref={requireInput} />
          </div>
          {formData.selectedServiceType.id === 1 ? (
            <div className="grid grid-cols-2 gap-y-6 lg:gap-x-3 z-10">
              <div className="col-span-2">
                <div className="grid grid-cols-5 gap-x-2 gap-y-3">
                  <div className="col-span-5 md:col-span-2">
                    <p className="body-font md:mt-3">
                      <span className="text-red-500">**</span> Activation Date
                    </p>
                  </div>
                  <div className="col-span-5 md:col-span-1 z-30">
                    <DateTimePicker
                      selectedDate={formData.selectedActivationDate}
                      placeHolder="Select Activation Date"
                      min={new Date()}
                      handleChangeOnPicker={(date) =>
                        handleChangeOnPicker(date, ACTIVATION)
                      }
                    />
                    {!formData.selectedActivationDate &&
                      errors.selectedActivationDate && (
                        <ErrorMessage text={errors.selectedActivationDate} />
                      )}
                  </div>
                </div>
              </div>
              <div className="col-span-2 lg:col-span-1">
                <div className="grid grid-cols-5 gap-x-2 gap-y-3">
                  <div className="col-span-5 md:col-span-2"></div>
                  <div className="col-span-5 md:col-span-2">
                    <div className="flex items-center flex-wrap space-x-3 md:mt-1">
                      {/* {LIST_OF_ACTIVATION_FEES.map((item) => (
                        <div key={item.id} className="mb-1">
                          <Badge
                            isSelected={
                              formData.selectedActivationFee.id === item.id
                            }
                            labelName={`${item.fee} ${item.unit}`}  
                            clickOnBadge={() =>
                              handleClickOnFees(item, ACTIVATION)
                            }
                          />
                        </div>
                      ))} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : formData.selectedServiceType.id === 2 ? (
            <div className="grid grid-cols-2 gap-y-6 lg:gap-x-3">
              <div className="col-span-2">
                <div className="grid grid-cols-5 gap-x-2 gap-y-3">
                  <div className="col-span-5 md:col-span-2">
                    <p className="body-font md:mt-3">
                      <span className="text-red-500">**</span> Renewal Date
                    </p>
                  </div>
                  <div className="col-span-5 md:col-span-1 z-30">
                    <DateTimePicker
                      selectedDate={formData.selectedRenewalDate}
                      placeHolder="Select Renewal Date"
                      min={new Date()}
                      handleChangeOnPicker={(date) =>
                        handleChangeOnPicker(date, RENEWAL)
                      }
                    />
                    {!formData.selectedRenewalDate &&
                      errors.selectedRenewalDate && (
                        <ErrorMessage text={errors.selectedRenewalDate} />
                      )}
                  </div>
                </div>
              </div>
              <div className="col-span-2 lg:col-span-1">
                <div className="grid grid-cols-5 gap-x-2 gap-y-3">
                  {/* <div className="col-span-5 md:col-span-2">
                    <p className="body-font md:mt-2">
                      <span className="text-red-500">**</span> Renewal Fee
                      &nbsp;
                      <span className="text-teal-400">
                        <small> (Exclusive Tax) </small>
                      </span>
                    </p>
                  </div> */}
                  {/* <div className="col-span-5 md:col-span-2">
                    <div className="flex items-center flex-wrap space-x-3 md:mt-1">
                      {LIST_OF_RENEWAL_FEES.map((item) => (
                        <div key={item.id} className="mb-1">
                          <Badge
                            isSelected={
                              formData.selectedRenewalFee.id === item.id
                            }
                            labelName={`${item.fee} ${item.unit}`}
                            clickOnBadge={() =>
                              handleClickOnFees(item, RENEWAL)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          ) : null}
          {/* <div className="col-span-4 md:col-span-2 lg:col-span-1">
            <p className="body-font">Service Detail Information</p>
          </div>
          <div className="col-span-4 md:col-span-2 lg:col-span-3 mt-1 lg:mt-0">
            <div className="flex items-center flex-wrap space-x-3">
              {TYPE_OF_SERVICE.map((serviceType) => (
                <div key={serviceType.id} className="mb-1">
                  <Badge
                    isSelected={
                      formData.selectedServiceType.id === serviceType.id
                    }
                    labelName={serviceType.serviceName}
                    clickOnBadge={() => handleClickOnServiceType(serviceType)}
                  />
                </div>
              ))}
            </div>
          </div> */}
          {/* {formData.selectedServiceType.id === 1 ? (
            <>
              <div className="col-span-4 lg:col-span-2 mt-3 md:mt-0">
                <div className="grid grid-cols-2 gap-y-1">
                  <div className="col-span-2 md:col-span-1">
                    <p className="body-font md:mt-3">
                      <span className="text-red-500">**</span> Activation Date
                    </p>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <DateTimePicker
                      selectedDate={formData.selectedActivationDate}
                      placeHolder="Select Activation Date"
                      min={new Date()}
                      
                      handleChangeOnPicker={(date) =>
                        handleChangeOnPicker(date, ACTIVATION)
                      }
                    />
                    {!formData.selectedActivationDate &&
                      errors.selectedActivationDate && (
                        <ErrorMessage text={errors.selectedActivationDate} />
                      )}
                  </div>
                </div>
              </div>
              <div className="col-span-4 lg:col-span-2 mt-3 md:mt-0">
                <div className="grid grid-cols-2 gap-y-1">
                  <div className="col-span-2 md:col-span-1">
                    <p className="body-font md:mt-3">
                      <span className="text-red-500">**</span> Annual Fee &nbsp;
                      <span className="text-teal-400">
                        <small> (Exclusive Tax) </small>
                      </span>
                    </p>
                  </div>
                  <div className="col-span-2 md:col-span-1 mt-1">
                    <div className="flex items-center flex-wrap space-x-3">
                      {LIST_OF_ACTIVATION_FEES.map((item) => (
                        <div key={item.id} className="mb-1">
                          <Badge
                            isSelected={
                              formData.selectedActivationFee.id === item.id
                            }
                            labelName={`${item.fee} ${item.unit}`}
                            clickOnBadge={() =>
                              handleClickOnFees(item, ACTIVATION)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : formData.selectedServiceType.id === 2 ? (
            <>
              <div className="col-span-4 lg:col-span-2 mt-3 md:mt-0">
                <div className="grid grid-cols-2 gap-y-1">
                  <div className="col-span-2 md:col-span-1">
                    <p className="body-font md:mt-3">
                      <span className="text-red-500">**</span> Renewal Date
                    </p>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <DateTimePicker
                      selectedDate={formData.selectedRenewalDate}
                      placeHolder="Select Renewal Date"
                      min={new Date()}
                      
                      handleChangeOnPicker={(date) =>
                        handleChangeOnPicker(date, RENEWAL)
                      }
                    />
                    {!formData.selectedRenewalDate &&
                      errors.selectedRenewalDate && (
                        <ErrorMessage text={errors.selectedRenewalDate} />
                      )}
                  </div>
                </div>
              </div>
              <div className="col-span-4 lg:col-span-2 mt-3 md:mt-0">
                <div className="grid grid-cols-2 gap-y-1">
                  <div className="col-span-2 md:col-span-1">
                    <p className="body-font md:mt-3">
                      <span className="text-red-500">**</span> Renewal Fee
                      &nbsp;
                      <span className="text-teal-400">
                        <small> (Exclusive Tax) </small>
                      </span>
                    </p>
                  </div>
                  <div className="col-span-2 md:col-span-1 mt-1">
                    <div className="flex items-center flex-wrap space-x-3">
                      {LIST_OF_RENEWAL_FEES.map((item) => (
                        <div key={item.id} className="mb-1">
                          <Badge
                            isSelected={
                              formData.selectedRenewalFee.id === item.id
                            }
                            labelName={`${item.fee} ${item.unit}`}
                            clickOnBadge={() =>
                              handleClickOnFees(item, RENEWAL)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null} */}
        </div>
      )}
    </div>
  );
};

export default ServiceTypeCharges;
