import React from "react";

const Divider = () => {
  return (
    <>
      <div className="w-full h-0.5 bg-gray-300" />
    </>
  );
};

export default Divider;
