export const TYPE_OF_DOMAIN = [
  {
    id: 1,
    typeName: "com.mm",
  },
  {
    id: 2,
    typeName: "edu.mm",
  },
  {
    id: 3,
    typeName: "org.mm",
  },
  {
    id: 4,
    typeName: "net.mm",
  },
  {
    id: 5,
    typeName: "gov.mm",
  },
  {
    id: 6,
    typeName: "biz.mm",
  },
  {
    id: 7,
    typeName: "per.mm",
  },
  {
    id: 8,
    typeName: "msme.mm",
  },
];
export const TYPE_OF_SERVICE = [
  {
    id: 1,
    serviceName: "New",
  },
  {
    id: 2,
    serviceName: "Reopen",
  },
];
export const LIST_OF_ACTIVATION_FEES = [
  {
    id: 1,
    fee: 50000,
    unit: "MMK",
  },
];
export const LIST_OF_RENEWAL_FEES = [
  {
    id: 1,
    fee: 50000,
    unit: "MMK",
  },
];
export const LIST_OF_TERMS_CONDITIONS = [
  {
    id: 1,
    text: `The Customer shall pay AGB the annual charges specified in the above table for this Service for the period (<strong>Initial Term</strong>). Upon expiry of the Initial Term, this Service shall be automatically renewed for a successive periods of <strong>one (1) year</strong> (each, a <strong>Renewal Term</strong>) unless either Party gives a written notice to the other of its intent not to renew this Service <strong>at least thirty (30) days</strong> prior to the expiration of the Initial Term or relevant Renewal Term. For any Renewal Term of this Service, the Customer shall pay AGB the annual charges specified in the above table, on a prepaid basis.`,
  },
  {
    id: 2,
    text: `Any and all Charges for this Service is subject to commercial tax and payment of Charges shall be made <strong>within seven (7) days</strong> from the date of invoice issued by AGB.`,
  },
  {
    id: 3,
    text: `The Customer acknowledge and agree that AGB is just providing a domain name and AGB is not responsible, and the Customer shall be responsible, for any and all matters arising out of or in connection with the website that the Customer creates with a domain name provided by AGB.`,
  },
  {
    id: 4,
    text: `If the Customer desires to terminate, cancel or suspend this service for any reason, the Customer shall inform by giving <strong>thirty (30) days</strong> prior written notice to AGB or to the email at <a href="mailto:billing@agbcommunication.com" className="text-blue-500 hover:underline"><i>billing@agbcommunication.com</i></a>, <a href="mailto:ispsale@agbcommunication.com" className="text-blue-500 hover:underline"><i>ispsale@agbcommunication.com</i></a>  before such termination, cancellation or suspension takes effect. In this regard, Customer shall not be entitled all and any portion of any charge that has been paid.`,
  },
];
export const LIST_OF_DOMAIN_MANAGEMENT = [
  {
    id: 1,
    text: "Yes",
    value: true,
  },
  {
    id: 2,
    text: "No",
    value: false,
  },
];
export const DNS_FIELD = {
  subDomainName: "",
  hostIPAddress: "",
};
