import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import masterDataProps from "../../utils/masterDataProp";
import moneyFormatter from "../../utils/moneyFormatter";

// Define a functional component named 'Hook'
const Hook = () => {
  // Destructuring values from the masterDataProps context
  const [
    setData,
    setIsOpen,
    setRequestDomain,
    setResponeDomain,
    setIsLoading,
    setIsRequestMoreDomainLoading,
    setIsDomainError,
    data,
    isOpen,
    requestDomain,
    responeDomain,
    isLoading,
    isRequestMoreDomainLoading,
    isDomainError,
  ] = useContext(masterDataProps);

  // State for total fees
  const [total, setTotal] = useState(0);
  const [mouseLeave, setMouseLeave] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);

  // State for total height of the data cart
  const [totalDataCartWidth, setTotalDataCartWidth] = useState(0);

  // Reference for the cart element
  const cartRef = useRef();

  // Function to handle opening/closing the cart
  const handleOnOpenCart = () => {
    // Open the cart only if there are items in the data
    if (data.length > 0) {
      setIsOpen(!isOpen);
    }
  };

  // Function to toggle an item in the cart
  const handleOnToggleCart = ({ domainName }) => {
    // Open the cart and remove the selected domain from the data
    setIsOpen(true);
    return setData(data.filter((domain) => domain.domainName !== domainName));
  };

  // Function to calculate total fees
  const totalFee = useCallback(() => {
    if (data.length > 1) {
      let total = 0;
      for (let i = 0; i < data.length; i++) {
        total += data[i].domainFee;
      }
      setTotal(moneyFormatter(total));
    } else if (data.length === 1) {
      setTotal(moneyFormatter(data[0].domainFee));
    }
  }, [data]);

  // Function to calculate the total height of the data cart
  const heightCalc = useCallback(() => {
    const totalHeight = cartRef.current.offsetHeight * data.length + 300;

    setTotalDataCartWidth(totalHeight);
  }, [data]);

  const handleOnMouseLeave = () => {
    setMouseLeave(true);
    setMouseOver(false);
  };
  const handleOnMouseOver = () => {
    // console.log(mouseOver);
    setMouseLeave(false);
    setMouseOver(true);
  };
  // Effect to recalculate heights when the cart is opened
  useEffect(() => {
    heightCalc();
    if (data.length) {
      totalFee();
    }
  }, [handleOnOpenCart]);

  // Effect to close the cart when there are no items
  useEffect(() => {
    if (!data.length) {
      setIsOpen(false);
    }
  }, [data]);

  useEffect(() => {
    if (mouseLeave && !mouseOver) {
      const timeoutId = setTimeout(() => {
        if (mouseLeave && !mouseOver) {
          setIsOpen(false);
          setMouseLeave(false);
          setMouseOver(false);
        }
      }, 5000);

      // Clear the timeout on component unmount or if the conditions change
      return () => clearTimeout(timeoutId);
    }
  }, [mouseLeave, mouseOver, handleOnMouseLeave, handleOnMouseOver]);

  useEffect(() => {
    if (!mouseLeave && !mouseOver && isOpen) {
      const timeoutId = setTimeout(() => {
        if (!mouseLeave && !mouseOver && isOpen) {
          setTimeout(() => {
            setIsOpen(false);
          }, 5000);
        }
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [isOpen, mouseOver, mouseLeave, handleOnToggleCart]);

  // Return an array containing necessary values and functions
  return [
    handleOnOpenCart,
    handleOnToggleCart,
    handleOnMouseLeave,
    handleOnMouseOver,
    isOpen,
    total,
    totalDataCartWidth,
    cartRef,
    data,
  ];
};

export default Hook;
