import React, { useContext, useEffect } from "react";
import masterDataProps from "../../utils/masterDataProp";

const Hook = () => {
  const [
    setData,
    setIsOpen,
    setRequestDomain,
    setResponeDomain,
    setIsLoading,
    setIsRequestMoreDomainLoading,
    setIsDomainError,
    data,
    isOpen,
    requestDomain,
    responeDomain,
    isLoading,
    isRequestMoreDomainLoading,
    isDomainError,
  ] = useContext(masterDataProps);
  const handleOnToggleCart = ({ domainName, domainStatus, domainFee }) => {
    if (!data.find((domain) => domain.domainName === domainName)) {
      setIsOpen(true);

      return setData((prev) => {
        return [
          ...prev,
          {
            domainName: domainName,
            domainStatus: domainStatus,
            domainFee: domainFee,
          },
        ];
      });
    }
    setIsOpen(true);
    return setData(data.filter((domain) => domain.domainName !== domainName));
  };

  useEffect(() => {
    localStorage.setItem("domainData", JSON.stringify(data));
  }, [data, setData]);

  return [handleOnToggleCart, data, responeDomain, isLoading, isDomainError];
};

export default Hook;
