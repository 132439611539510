import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import WellDoneIllustration from "../../assets/imgs/well-done.svg";

//components
import Header from "../../ControlledComponents/Header/header.js";
import { DEFAULT_ROUTE } from "../../constants/routePathName.js";

const RegistrationSuccessPage = () => {
  const navigate = useNavigate();
  return (
    <div className="mx-auto container">
      <div className="w-full h-screen flex justify-center items-center">
        <div className="space-y-8">
          <img
            src={WellDoneIllustration}
            className="w-3/5 h-auto mx-auto"
            alt="WellDoneImg"
          />
          <p className="font-serif text-2xl text-center">
            THANK FOR INTERESTING OUR SERVICES.
          </p>
          <p
            className="hover:text-blue-500 text-slate-400 cursor-pointer text-center"
            onClick={() => navigate(DEFAULT_ROUTE)}
          >
            <FontAwesomeIcon icon={faArrowRight} className="text-lg" />{" "}
            <span>...... Let's register again!</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegistrationSuccessPage;
