export const checkIPAddress = (value) => {
  let message = "";
  // let regex =
  //   /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  let regex =
    /^([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(?<!172\.(16|17|18|19|20|21|22|23|24|25|26|27|28|29|30|31))(?<!127)(?<!^10)(?<!^0)\.([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(?<!192\.168)(?<!172\.(16|17|18|19|20|21|22|23|24|25|26|27|28|29|30|31))\.([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
  if (regex.test(value)) {
    return message;
  }
  message = "Please fill out the valid IP Address!";
  return message;
};

export const checkEmailAddress = (value) => {
  let message = "";
  let regex =
    /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
  if (regex.test(value)) {
    return message;
  }
  message = "Please fill out the valid Email Address!";
  return message;
};

export const checkPhoneNumber = (value) => {
  let message = "";
  let regex = /^([^0-9]*[0-9]){7,11}[^0-9]*$/;
  if (regex.test(value)) {
    return message;
  }
  if (value === "") {
    return message;
  }
  message = "Please fill out the valid Phone Number!";
  return message;
};

export const checkErrorForAllFormData = (errors) => {
  if (
    // errors.hostIPAddress ||
    errors.appContactEmail ||
    errors.appContactPhone ||
    errors.billContactEmail ||
    errors.billContactPhone ||
    errors.billOfficePhone ||
    errors.techContactEmail ||
    errors.techContactPhone
  ) {
    return true;
  }

  return false;
};
